import {
    URL_COMPUTE_IMAGE_API_BASE,
    URL_COMPUTE_IMAGE_API_CREATE,
    URL_COMPUTE_IMAGE_API_ITEM,
} from "../../router/urls";
import { apiService } from "../api-request.service";
import {AgogeImage} from "./image.model";
import {transformToAgogeImage, transformToAgogeImageList} from "../../utilities/transformers/image.transform";
import {PubSub} from "../../types/PubSub";
import formatString from "../../utilities/formatString";

const list = async() =>{
    return await apiService.get<AgogeImage[]>(
        URL_COMPUTE_IMAGE_API_BASE,
        null,
        true,
        transformToAgogeImageList
    );
}

const get = async (imageName: string) => {
    const endpoint = formatString(URL_COMPUTE_IMAGE_API_ITEM, {"ITEM_ID": imageName})
    return await apiService.get<AgogeImage>(endpoint,null,true, transformToAgogeImage);
}

const post_action = async (images: AgogeImage[], action: string) => {
    const postData = JSON.stringify({
        "images": images,
        'action': action,
    });
    return await apiService.post<AgogeImage>(
        URL_COMPUTE_IMAGE_API_BASE,
        postData,
        true,
        transformToAgogeImage
    );
}

const create = async (image: { [k: string]: FormDataEntryValue } ) => {
    image['action'] = PubSub.Actions.IMAGE_SERVER_CREATE.toString();
    const postData = JSON.stringify(image);
    return await apiService.post<AgogeImage>(
        URL_COMPUTE_IMAGE_API_CREATE,
        postData,
        true,
        transformToAgogeImage
    );
}

const delete_image = async (imageId: string)=> {
    const endpoint = formatString(URL_COMPUTE_IMAGE_API_ITEM, {"ITEM_ID": imageId});
    return await apiService.del(endpoint, true,);
}

export const imageService = {
    create,
    get,
    list,
    post_action,
    delete_image
}