import React, { FormEvent, useState } from 'react';
import { Box, CircularProgress, FormControl, TextField, Typography, useTheme } from '@mui/material';
import { AgogeLogo } from "../Common/Logo";
import Button from "@mui/material/Button";
import { workoutService } from "../../services/Workout/workout.service";
import { URL_STUDENT_WORKOUT } from "../../router/urls";
import { Navigate, Form } from "react-router-dom";

const JoinWorkout: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [redirect, setRedirect] = useState(false);
    const [buildId, setBuildId] = useState<string | null>(null);
    const theme = useTheme();

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        const formData = new FormData(event.currentTarget);
        const formObject = Object.fromEntries(formData.entries());

        try {
            const resp = await workoutService.post(formObject);
            if (resp?.build_id && !resp?.exists) {
                setBuildId(resp.build_id);
                await poll(resp.build_id);
            } else if (resp?.build_id && resp?.exists) {
                setBuildId(resp.build_id);
                setRedirect(true);
            }
        } catch (error: any) {
            if (error?.detail) {
                setError(error.detail);
            } else {
                setError('An error occurred during submission.');
            }
            setLoading(false);
        }
    };

    const poll = async (buildId: string, maxErrors = 10) => {
        let errorCount = 0;

        const fetchAndCheck = async () => {
            try {
                const data = await workoutService.get_state(buildId);

                if (data?.state && Number(data.state) >= 0) {
                    setRedirect(true);
                } else {
                    setTimeout(fetchAndCheck, 3000);
                }
            } catch (error) {
                console.error('Error during fetch:', error);
                errorCount++;
                if (errorCount >= maxErrors) {
                    setError('No build found for given ID');
                    setLoading(false);
                } else {
                    console.log('Retrying in 3 seconds...');
                    setTimeout(fetchAndCheck, 3000);
                }
            }
        };

        fetchAndCheck();
    };

    return (
        <Box
            id="homePage"
            sx={{
                backgroundColor: '#1c2538',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'black'
            }}
        >
            <Box id="main" sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: 4,
                p: 2,
                boxShadow: 3,
                maxWidth: 500,
            }}>
                <AgogeLogo contained={false}/>
                <Box id="claim-container" sx={{ textAlign: 'center', maxWidth: 500, mx: 'auto' }}>
                    {loading ? (
                        <Box id="loader" sx={{ textAlign: 'center' }}>
                            <CircularProgress />
                            <Typography variant="h6">Finding your lab ...</Typography>
                            <Typography>This could take a few minutes.</Typography>
                        </Box>
                    ) : (
                        <Box id="claim-form-container">
                            <Typography textAlign="center" m={1}>Claim Your Lab</Typography>
                            <Form onSubmit={handleSubmit}>
                                <FormControl fullWidth required margin="normal">
                                    <TextField
                                        size="small"
                                        id="join-code"
                                        name="join_code"
                                        label="Join Code"
                                        type="text"
                                        autoComplete="off"
                                        InputLabelProps={{
                                            style: { color: '#1c2538' }
                                        }}
                                        InputProps={{
                                            sx: {
                                                color: 'black',
                                                borderColor: '#1c2538',
                                            }
                                        }}
                                        sx={{
                                            color: 'black',
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#1c2538',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#1c2538',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#1c2538',
                                                },
                                            }
                                        }}
                                    />
                                </FormControl>
                                <FormControl required fullWidth>
                                    <TextField
                                        size="small"
                                        id="email"
                                        name="input_email"
                                        label="Email"
                                        helperText="We'll never share your email with anyone else."
                                        autoComplete="off"
                                        FormHelperTextProps={{
                                            style: { color: '#1c2538' }
                                        }}
                                        InputLabelProps={{
                                            style: { color: '#1c2538' }
                                        }}
                                        InputProps={{
                                            sx: {
                                                color: 'black',
                                                borderColor: '#1c2538',
                                            }
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#1c2538',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#1c2538',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#1c2538',
                                                },
                                            }
                                        }}
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        marginTop: 3,
                                        width: '50%',
                                        backgroundColor: '#1c2538',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#39455e',
                                        },
                                    }}
                                >
                                    Submit
                                </Button>
                            </Form>
                        </Box>
                    )}
                    {error && (
                        <Typography
                            variant="body1"
                            color="error"
                            id="error-msg"
                            sx={{ marginTop: 2 }}
                        >
                            {error}
                        </Typography>
                    )}
                </Box>
            </Box>
            {redirect && buildId && (
                <Navigate to={`${URL_STUDENT_WORKOUT}/${buildId}`} />
            )}
        </Box>
    );
};

export default JoinWorkout;
