import React from "react";
import Chip from "@mui/material/Chip";
import {Tooltip} from "@mui/material";
import {AppUiObjectNames} from "../../../types/AppObjectNames";
import {LoadingState} from "./LoadingState";


interface StateInfoProps {
    label: string;
    context?: AppUiObjectNames.UNIT | AppUiObjectNames.WORKOUT;
    variant?: "outlined" | "filled" | undefined
    color?:  "success" | "error" | "warning" | "info" | "primary" | "secondary" | "default" | undefined;
    loading?: boolean;
}

export const StateInfo: React.FC<StateInfoProps> = (props) => {
    const context = props.context || AppUiObjectNames.WORKOUT

    return (
        <>
            <Tooltip
                title={`Status: ${context} ${props.label}`}
                aria-label={`${context} Status`}
                arrow
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -8],
                                },
                            },
                        ],
                    },
                }}
            >
                {props.loading ? (
                    <LoadingState
                        label={props.label.toUpperCase()}
                        variant={props.variant || "outlined"}
                        color={props.color || "info"}
                        width={"100%"}
                    />
                ) : (
                    <Chip
                        label={props.label.toUpperCase()}
                        variant={props.variant || "outlined"}
                        color={props.color || "info"}
                        sx={{
                            borderRadius: 1,
                            '.MuiChip-label': {
                                fontSize: 18,
                                letterSpacing: 2
                            },
                            width: "100%",
                        }}
                        size={"medium"}
                    />
                )}

            </Tooltip>
        </>
    );
}