import React from 'react';
import { Box } from '@mui/material';
import FirebaseAuth from './FirebaseAuthUI';

const Login: React.FC = () => {
    return (
        <Box
            id="homePage"
            sx={{
                backgroundColor: '#1c2538',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <FirebaseAuth />
        </Box>
    );
};

export default Login;
