import React from 'react';
import Chip from '@mui/material/Chip';
import { SxProps } from '@mui/system';

interface StatusBadgeProps {
    color: string;
    label: string;
    sx?: SxProps;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ color, label, sx }) => {
    return (
        <Chip
            label={label}
            sx={{
                backgroundColor: color,
                color: 'white',
                minWidth: '75px',
                textAlign: 'center',
                fontSize: '0.75rem',
                lineHeight: 1.5,
                height: '24px',
                ...sx,
            }}
        />
    );
};

export default StatusBadge;
