import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import FirebaseApp from "../Firebase/firebase";

FirebaseApp();

let firebaseUiInstance: firebaseui.auth.AuthUI | null = null;

export const auth = firebase.auth();

export const getFirebaseAuthUi = (): firebaseui.auth.AuthUI => {
    if (!firebaseUiInstance) {
        firebaseUiInstance = new firebaseui.auth.AuthUI(auth);
    }
    return firebaseUiInstance;
};

export const configureFirebaseLoginWidget = (setUser: (user: any) => void, setRedirect: (url: string) => void): firebaseui.auth.Config => {
    return {
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                document.getElementById('loader')!.style.display = 'block';
                authResult.user.getIdToken().then((idToken: string) => {
                    return fetch(`${process.env.REACT_APP_AGOGE_API_URL!}auth/`, {
                        method: 'POST',
                        headers: {
                            "Authorization": `Bearer ${idToken}`,
                        }
                    });
                }).then((response: { json: () => any; }) => response.json())
                    .then((result: { data: any; redirect: string; error: any; }) => {
                        if (result.data) {
                            setUser(result.data);
                            document.getElementById('loader')!.style.display = 'none';

                            // Get the redirect URL from session storage or default to home page
                            const redirectUrl = sessionStorage.getItem('redirectAfterLogin') || '/';
                            sessionStorage.removeItem('redirectAfterLogin');
                            setRedirect(redirectUrl);
                        } else {
                            throw new Error(result.error || 'Failed to authenticate');
                        }
                    })
                    .catch((error: { message: any; toString: () => string | null; }) => {
                        if (error instanceof Error) {
                            console.error(error.message);
                            document.getElementById('loader')!.style.display = 'none';
                            document.getElementById('error-msg')!.textContent = error.toString();
                        } else {
                            console.error('Unknown error', error);
                        }
                        setTimeout(() => {
                            window.location.reload();
                        }, 5000);
                    });
                return false;
            },
            uiShown: () => {
                document.getElementById('loader')!.style.display = 'none';
            }
        },
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        signInSuccessUrl: '/home',
    };
};
