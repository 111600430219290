import { apiService } from "../api-request.service";
import {AgogeUser, UserCourses} from "./user.model";
import {
    URL_USER_API_BASE, URL_USER_API_COURSES,
    URL_USER_API_SETTINGS, URL_USER_API_UID
} from "../../router/urls";
import {
    transformToAgogeUser,
    transformToCoursesList,
    transformToUserList,
} from "../../utilities/transformers/user.transform";
import formatString from "../../utilities/formatString";

const get = async (uid: string) => {
    const endpoint = formatString(URL_USER_API_UID, {"ITEM_ID": uid});
    return await apiService.get<AgogeUser>(endpoint, null, true, transformToAgogeUser);
}

const get_courses = async (uid: string) => {
    const endpoint = formatString(URL_USER_API_COURSES, {"ITEM_ID": uid});
    return await apiService.get<UserCourses>(endpoint, null, true, transformToCoursesList);
}

const update_settings = async (uid: string, unitData: { [key: string]: any }) => {
    const endpoint = formatString(URL_USER_API_SETTINGS, {"ITEM_ID": uid});
    return await apiService.post<AgogeUser>(endpoint, unitData, true, transformToAgogeUser);
}

const list = async () => {
    return await apiService.get<AgogeUser[]>(URL_USER_API_BASE, null, true, transformToUserList);
}

const create = async (unitData: any) => {
    return await apiService.post<AgogeUser>(URL_USER_API_BASE, unitData, true, transformToAgogeUser);
}

const delete_user = async (uid: string)=> {
    const endpoint = formatString(URL_USER_API_UID, {"ITEM_ID": uid});
    return await apiService.del<AgogeUser>(endpoint, true,);
}

const update_permissions = async (uid: string, unitData: { [key: string]: any})=> {
    const endpoint = formatString(URL_USER_API_UID, {"ITEM_ID": uid});
    return await apiService.post<AgogeUser>(endpoint, unitData, true, transformToAgogeUser);
}


export const userService = {
    get,
    get_courses,
    update_settings,
    list,
    create,
    delete_user,
    update_permissions,
}