import React from "react";
import { FormControl, TextField } from "@mui/material";

interface ListInputFieldProps {
    id: string;
    name: string;
    label: string;
    helperText?: string;
    required?: boolean;
    maxRows?: number;
    maxLength?: number;
    type?: string;
    error?: boolean;
    onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextAreaField: React.FC<ListInputFieldProps> = (
    {
        id,
        name,
        label,
        helperText,
        required,
        maxRows,
        maxLength,
        type,
        error,
        onInputChange,
    }
) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onInputChange) {
            onInputChange(event);
        }
    }

    return (
        <FormControl fullWidth required={required}>
            <TextField
                id={id}
                name={name}
                label={label}
                type={type}
                helperText={helperText}
                minRows={2}
                maxRows={maxRows || 5}
                margin={"normal"}
                multiline={true}
                onChange={handleInputChange}
                inputProps={{
                    maxLength: maxLength
                }}
                InputProps={{
                    style: {
                        overflow: "auto"
                    },
                }}
                error={error}
            />
        </FormControl>
    );
};

export default TextAreaField;
