import React, { useState } from 'react';
import {
    Button,
    CircularProgress,
    Snackbar,
    Alert,
    AlertTitle,
} from '@mui/material';
import {imageService} from "../../services/Server/image.service";
import { unitService } from "../../services/Unit/unit.service";
import {workoutService} from "../../services/Workout/workout.service";
import {PlayArrowOutlined, DangerousOutlined, PlusOne} from "@mui/icons-material";
import {PubSub} from "../../types/PubSub";
import AddIcon from "@mui/icons-material/Add";

interface ButtonProps {
    text: string;
    onClick?: () => void;
    isDisabled?: boolean;
    sx?: any;
    use: string;
    data?: any;
    days?: number;
}

interface ExtendButtonProps extends ButtonProps {
    additionalOnClick?: () => void;
}

type SnackbarSeverity = 'success' | 'error' | 'warning' | 'info';

interface SnackbarState {
    open: boolean;
    severity: SnackbarSeverity;
    message: string;
}

/**
 * Usage with Custom Button Example
 *
 * To use the StartButton, StopButton, and ExtendButton components:
 *
 * 1. Define the props for each button including `text`, `onClick` (optional), `isDisabled` (optional), `sx` (optional), `use`, and `data`.
 * 2. Use the buttons in your render method, passing the defined props.
 *
 * Example:
 *
 * const handleStart = () => {
 *     console.log('Start button clicked');
 * };
 *
 * const handleStop = () => {
 *     console.log('Stop button clicked');
 * };
 *
 * const handleExtend = () => {
 *     console.log('Extend button clicked');
 * };
 *
 * const data = {
 *     id: 1,
 *     name: 'Example Item'
 * };
 *
 * <StartButton
 *     text="Start"
 *     onClick={handleStart}
 *     isDisabled={false}
 *     sx={{ margin: 1 }}
 *     use="workout"
 *     data={data}
 * />
 *
 * <StopButton
 *     text="Stop"
 *     onClick={handleStop}
 *     isDisabled={false}
 *     sx={{ margin: 1 }}
 *     use="server"
 *     data={data}
 * />
 *
 * <ExtendButton
 *     text="Extend"
 *     onClick={handleExtend}
 *     isDisabled={false}
 *     sx={{ margin: 1 }}
 *     use="assignment"
 *     data={data}
 * />
 *
 * To use the ConnectButton component:
 *
 * 1. Define the `item` and `context` props.
 * 2. Use the ConnectButton in your render method, passing the defined props.
 *
 * Example:
 *
 * const item = {
 *     name: 'Example Server',
 *     hostname: 'example.hostname.com',
 *     human_interaction: JSON.stringify({
 *         username: 'user123',
 *         password: 'pass123'
 *     })
 * };
 *
 * <ConnectButton
 *     item={item}
 *     context="server"
 * />
 */

const buttonController = async (useCase: string, data: any, action: string) => {
    try {
        switch (useCase) {
            case 'server':
                await imageService.post_action(data, action);
                break;
            case 'assignment':
                if (data.days !== undefined){
                    await unitService.put_action(data.id, action, data.days);
                } else {
                    await unitService.put_action(data, action);
                }
                break;
            case 'workout':
                if ( data.duration !== undefined ){
                    await workoutService.put_action(data.id, action, data.duration);
                } else {
                    await workoutService.put_action(data.id, action);
                }
                break;
            default:
                break;
        }
    } catch (error) {
        console.error('Error', error);
    }
};

export const StartButton: React.FC<ButtonProps> = ({ onClick, text, isDisabled, sx, use, data }) => {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackbarState>({ open: false, severity: 'success', message: '' });

    const defaultOnClick = async () => {
        setLoading(true);
        await buttonController(use, data, PubSub.Actions.START.toString());
        setLoading(false);
        setSnackbar({ open: true, severity: 'success', message: 'Starting ...' });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <>
            <Button
                onClick={onClick || defaultOnClick}
                sx={sx}
                disabled={isDisabled || loading}
                startIcon={!loading && <PlayArrowOutlined fontSize="large" />}
                variant="outlined"
                color={"success"}
            >
                {loading ? <CircularProgress size={24} color={"info"} /> : text}
            </Button>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    <AlertTitle>{snackbar.severity.charAt(0).toUpperCase() + snackbar.severity.slice(1)}</AlertTitle>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export const StopButton: React.FC<ButtonProps> = ({ onClick, text, isDisabled, sx, use, data }) => {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackbarState>({ open: false, severity: 'success', message: '' });

    const defaultOnClick = async () => {
        setLoading(true);
        await buttonController(use, data, PubSub.Actions.STOP.toString());
        setLoading(false);
        setSnackbar({ open: true, severity: 'success', message: 'Operation completed successfully!' });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <>
            <Button
                onClick={onClick || defaultOnClick}
                sx={sx}
                disabled={isDisabled || loading}
                startIcon={!loading && <DangerousOutlined fontSize="large" />}
                variant="outlined"
                color={"secondary"}
            >
                {loading ? <CircularProgress size={24} color={"info"} /> : text}
            </Button>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    <AlertTitle>{snackbar.severity.charAt(0).toUpperCase() + snackbar.severity.slice(1)}</AlertTitle>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export const ExtendButton: React.FC<ExtendButtonProps> = ({ onClick, additionalOnClick, text, isDisabled, sx, use, data }) => {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackbarState>({ open: false, severity: 'success', message: '' });

    const defaultOnClick = async () => {
        setLoading(true);
        const action = use === 'workout' ? String(PubSub.Actions.EXTEND_RUNTIME) : String(PubSub.Actions.RESET_EXPIRATION);
        await buttonController(use, data, action);
        setLoading(false);
        setSnackbar({ open: true, severity: 'success', message: 'Operation completed successfully!' });

        if (additionalOnClick) {
            additionalOnClick();
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <>
            <Button
                onClick={onClick || defaultOnClick}
                sx={sx}
                disabled={isDisabled || loading}
                variant="contained"
                color={"info"}
                endIcon={<AddIcon />}
            >
                {loading ? <CircularProgress size={24} color="info" /> : text}
            </Button>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    <AlertTitle>{snackbar.severity.charAt(0).toUpperCase() + snackbar.severity.slice(1)}</AlertTitle>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};
