import React from "react";
import {
    Box, Button,
    Divider,
    IconButton,
    Link,
    Typography
} from "@mui/material";
import {WorkoutStates} from "../../../types/AgogeStates";
import {BaseConnectButton, ConnectButtonProps} from "./BaseConnectButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useClipboard} from "../../../hooks/useClipboard";
import {OpenInBrowser} from "@mui/icons-material";
import {URL_STUDENT_WORKOUT} from "../../../router/urls";


export const WorkoutConnectButton: React.FC<ConnectButtonProps> = (props) => {
    const {copy} = useClipboard();
    const parsedHumanInteraction = JSON.parse(props.item.human_interaction)[0];
    const protocol = parsedHumanInteraction.protocol;
    const hostname = props.item.dns_record || props.item.hostname;

    const isDisabled = () => props.item.state !== WorkoutStates.RUNNING;

    const renderGuacamoleConnect = () => {
        if (props.addGuacamole && props.buildId && props.serverIdx) {
            const endpoint = `${URL_STUDENT_WORKOUT}/${props.buildId}/guacamole/${props.serverIdx}`
            return (
                <>
                    <Divider sx={{my: 2}} />
                    <Box>
                        <Typography variant={"h5"} sx={{my: 1}}>Alternative Connection Method</Typography>
                        <Typography variant={"body1"} my={1}>
                           If you are unable to use {protocol.toUpperCase()} on your machine,
                            you can connect via Guacamole here:
                        </Typography>
                        <Button
                            variant={"contained"}
                            color={"success"}
                            href={endpoint}
                            target={"_blank"}
                            endIcon={<OpenInBrowser />}
                            aria-label={"Link to Guacamole server session"}
                        >
                            Connect with Guacamole
                        </Button>
                    </Box>
                </>
            );
        } else {
            return null;
        }
    }

    const renderDetails = (item: any) => {
        return (
            <>
                {protocol === 'rdp' && (
                    <>
                        <Typography id="rdp-connect-modal-description" sx={{ mt: 2 }} variant="h6">
                            RDP
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            To connect to this server, connect using your system's RDP app with the server details provided below.
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            <ul>
                                <li>On Windows, this is Remote Desktop Connection</li>
                                <li>
                                    On Mac, it is Microsoft Remote Desktop (
                                    <Link href="https://apps.apple.com/us/app/microsoft-remote-desktop/id1295203466" target="_blank" rel="noopener">
                                        Download here
                                    </Link>
                                    )
                                </li>
                            </ul>
                        </Box>
                        <Divider />
                        <Box component="section" paddingTop={1}>
                            <Typography>Hostname</Typography>
                            <Box
                                component="section"
                                sx={{
                                    p: 1,
                                    border: '1px solid grey',
                                    borderRadius: '4px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        mt: 1,
                                        wordBreak: 'break-all',
                                        background: 'background.paper',
                                        borderRadius: '4px'
                                    }}
                                >
                                    <em>{hostname}</em>
                                    <IconButton onClick={() => copy(hostname)} sx={{ ml: 1 }}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Typography>
                            </Box>
                            <Typography sx={{ mt: 2 }}>Username</Typography>
                            <Box
                                component="section"
                                sx={{
                                    p: 1,
                                    border: '1px solid grey',
                                    borderRadius: '4px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        mt: 1,
                                        wordBreak: 'break-all',
                                        background: 'background.paper',
                                        borderRadius: '4px'
                                    }}
                                >
                                    <em>{parsedHumanInteraction.username}</em>
                                    <IconButton onClick={() => copy(parsedHumanInteraction.username)} sx={{ ml: 1 }}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Typography>
                            </Box>

                            <Typography sx={{ mt: 2 }}>Password</Typography>
                            <Box
                                component="section"
                                sx={{
                                    p: 1,
                                    border: '1px solid grey',
                                    borderRadius: '4px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        mt: 1,
                                        wordBreak: 'break-all',
                                        background: 'background.paper',
                                        borderRadius: '4px'
                                    }}
                                >
                                    <em>{parsedHumanInteraction.password}</em>
                                    <IconButton onClick={() => copy(parsedHumanInteraction.password)} sx={{ ml: 1 }}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Typography>
                            </Box>
                        </Box>

                        {renderGuacamoleConnect()}
                    </>
                )}
                {protocol === 'ssh' && (
                    <>
                        <Typography id="ssh-connect-modal-description" sx={{ mt: 2 }} variant="h6">
                            SSH
                        </Typography>
                        <Typography sx={{ mt: 2, mb: 2 }}>
                            On your machine, open up a terminal/command prompt and run the following command
                            and enter the password listed below when prompted.
                        </Typography>
                        <Divider />
                        <Box sx={{ mt: 2 }}>
                            <Typography>Command:</Typography>
                            <Box
                                component="section"
                                sx={{
                                    p: 1,
                                    border: '1px solid grey',
                                    borderRadius: '4px'
                                }}
                            >
                                {hostname && (
                                    <>
                                        <Typography >
                                            <em>ssh {parsedHumanInteraction.username}@{hostname}</em>
                                            <IconButton onClick={() => copy(`ssh ${parsedHumanInteraction.username}@${hostname}`)} sx={{ ml: 1 }}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Typography>

                                    </>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography>User Password:</Typography>
                            <Box
                                component="section"
                                sx={{
                                    p: 1,
                                    border: '1px solid grey',
                                    borderRadius: '4px'
                                }}
                            >
                                <Typography sx={{ mt: 1, wordBreak: 'break-all' }}>
                                    <em>{parsedHumanInteraction.password}</em>
                                    <IconButton
                                        onClick={() => copy(parsedHumanInteraction.password)}
                                        sx={{ ml: 1 }}
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Typography>
                            </Box>
                        </Box>

                        {renderGuacamoleConnect()}
                    </>
                )}
            </>
        );
    };

    return <BaseConnectButton item={props.item} isDisabled={isDisabled} renderDetails={renderDetails} />;
};