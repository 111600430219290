import React, { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import { WorkoutStates, ImageStates, ServerStates } from "../../../types/AgogeStates";

interface StateChipProps {
    state: number;
    stateType: "workout" | "image" | "server";
    size: "small" | "medium"
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ChipColors {
    [key: number]: [string, string];
}

const chipColors: ChipColors = {
    1: ["#7a7a7a", "black"],
    2: ["#ada500", "black"],
    50: ['#388e3c', "black"],
    53: ['#42a5f5', "black"],
    62: ['#ff1744', "black"],
    72: ['#000000', "white"]
};

const StateChip: React.FC<StateChipProps> = (
    { state, stateType, size, onChange }
) => {
    const [states, setStates] = useState<{ [key: number]: string }>({});

    useEffect(() => {
        if (stateType === "server") {
            setStates(ServerStates);
        } else if (stateType === "image") {
            setStates(ImageStates);
        } else {
            setStates(WorkoutStates);
        }
    }, [stateType]);

    const renderChip = () => {
        if (state in states) {
            let label = states[state];
            let chipColor = chipColors[state] || chipColors[2];
            return (
                <Chip
                    label={label}
                    variant={"filled"}
                    size={size}
                    style={{
                        backgroundColor: chipColor[0],
                        color: chipColor[1],
                        textOverflow: "ellipsis"
                    }}
                    onChange={onChange}
                />
            );
        }
        return (
            <Chip
                label="Working"
                variant={"filled"}
                size={size}
                style={{
                    backgroundColor: chipColors[2][0],
                    color: chipColors[2][1]
                }}
                onChange={onChange}
            />
        );
    };

    return renderChip();
}

export default StateChip;
