import React, {useState, useEffect, ChangeEvent} from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import AppRouter from "./router/AppRouter";
import { darkTheme, lightTheme } from './styles/theme';
import ModalProvider from "mui-modal-provider";

const App: React.FC = () => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme ? JSON.parse(savedTheme) : true;
    });

    useEffect(() => {
        localStorage.setItem('theme', JSON.stringify(isDarkMode));
    }, [isDarkMode]);

    const toggleTheme = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsDarkMode(checked);
    };

    return (
        <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <CssBaseline />
            <ModalProvider>
                <AppRouter toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
            </ModalProvider>
        </ThemeProvider>
    );
};

export default App;
