import {GridRenderCellParams} from "@mui/x-data-grid";
import React from "react";
import {Link, Typography} from "@mui/material";

interface ExpandableCellProps extends GridRenderCellParams {
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2';
}

export const ExpandableCell: React.FC<ExpandableCellProps> = ({ value, variant }) => {
    const [expanded, setExpanded] = React.useState(false);

    const renderContent = () => (
        <>
            {expanded ? value : value.slice(0, 100)}&nbsp;
            {value.length > 100 && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                    type="button"
                    component="button"
                    sx={{ fontSize: 'inherit' }}
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? 'View Less' : 'View More'}
                </Link>
            )}
        </>
    );

    if (!value) {
        return variant ? (
            <Typography variant={variant}>No lab description</Typography>
        ) : (
            <div>No lab description</div>
        );
    }

    return variant ? (
        <div>
            <Typography variant={variant}>
                {renderContent()}
            </Typography>
        </div>
    ) : (
        <div>
            {renderContent()}
        </div>
    );
}