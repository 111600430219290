import React, { useEffect, useState } from 'react';
import { Box, TextField, FormControl, Button } from '@mui/material';
import {userService} from "../../../services/User/user.service";
import {AgogeUser} from "../../../services/User/user.model";
import { useAuth } from '../../../context/AuthContext';
import SimpleSnackbar from "../../Common/SnackBar/SnackBar";
import Typography from "@mui/material/Typography";
import {useModal} from "mui-modal-provider";

const SettingsBoxesV2: React.FC = () => {
    const {user} = useAuth();
    const userId = user ? user.uid : "";
    const [agogeUser, setSettingsData] = useState<AgogeUser>()
    const [apiInput, setApiInput]= useState("loading")
    const [urlInput, setUrlInput]= useState("loading")
    const {showModal} = useModal();

    useEffect(() => {
        const fetchSettings = async () => {
            try{
                const fetchedAgogeUser = await userService.get(userId);
                setSettingsData(fetchedAgogeUser);

                //Loading these into variables to load onto the page at the start
                setApiInput(fetchedAgogeUser?.settings.canvas.api ? (fetchedAgogeUser?.settings.canvas.api) : ("") );
                setUrlInput(fetchedAgogeUser?.settings.canvas.url ? (fetchedAgogeUser?.settings.canvas.url) : (""));
            } catch (error) {
                console.log("Failed to fetch user data.")
            } finally { }
        };
        fetchSettings();
    }, [userId]);

    const [formData, setFormData] = useState({
        uid: userId,
        email: (agogeUser?.email ? (agogeUser?.email) : ("") ),
        name: (agogeUser?.name ? (agogeUser?.name) : ("") ),
        permissions: {
            pending: (agogeUser?.permissions? (agogeUser?.permissions.pending) : false ),
            admin: (agogeUser?.permissions? (agogeUser?.permissions.admin) : true ),
            instructor: (agogeUser?.permissions? (agogeUser?.permissions.instructor) : true ),
            student: (agogeUser?.permissions? (agogeUser?.permissions.student) : true )
        },
        settings: {
            canvas: {
                api: (agogeUser?.settings.canvas.api ? (agogeUser?.settings.canvas.api) : ("") ),
                url: (agogeUser?.settings.canvas.api ? (agogeUser?.settings.canvas.url) : ("")),
                secret: (agogeUser?.settings.canvas.secret ? (agogeUser?.settings.canvas.secret) : (""))
            }
        }
    })

    const handleAPIChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setApiInput(e.target.value)
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            'email': (agogeUser?.email ? (agogeUser?.email) : ("")),
            'name': (agogeUser?.name ? (agogeUser?.name) : ("")),
            settings: {
                ...prevState.settings,
                canvas: {
                    ...prevState.settings.canvas,
                    [name]: value
                }
            }
        }));

    };

    const handleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrlInput(e.target.value)
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            'email': (agogeUser?.email ? (agogeUser?.email) : ("")),
            'name': (agogeUser?.name ? (agogeUser?.name) : ("")),
            settings: {
                ...prevState.settings,
                canvas: {
                    ...prevState.settings.canvas,
                    [name]: value
                }
            }
        }));

    };


    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await userService.update_settings(userId, formData);
            showModal(SimpleSnackbar, {message: "Successfully updated settings!"})

        } catch (error){
            showModal(SimpleSnackbar, {message: "Failed to update settings."})
        }
    };

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <Typography
                variant={"h5"}
                component={"h2"}
                paddingBottom={2}
            >
                LMS Configuration
            </Typography>
            <Box id="canvas">
                <label>API Key (Canvas)</label>
                <FormControl sx={{marginY: 1}} fullWidth>
                    <TextField
                        name="api"
                        value={apiInput}
                        onChange={handleAPIChange}
                        variant="outlined"
                        required={true}
                    />
                </FormControl>
                <FormControl sx={{marginY: 1}} fullWidth>
                    <label>URL (Canvas)</label>
                    <TextField
                        name="url"
                        value={urlInput}
                        onChange={handleURLChange}
                        variant="outlined"
                        required={true}
                    />
                </FormControl>
            </Box>
            <Box id="save button">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default SettingsBoxesV2;