import React from "react";
import {Button, List, Paper, Skeleton, Stack, useTheme} from "@mui/material";
import {SpecificationEdit} from "../../../../../services/Specification/specificationEdit.model";
import {ReviewCommonChildrenProps} from "./Review";
import {EditorForms} from "../utils/EditorTypes";
import {EditOutlined} from "@mui/icons-material";

import {
    EmptyListItem, EmptyListItemText,
    NestedReviewListItem,
    ReviewHeaderKey,
    ReviewHeaderValue,
    ReviewItemKey, ReviewItemKeyWrapper, ReviewItemValue,
    ReviewListHeader,
    ReviewListItem, ReviewSectionLabel
} from './ReviewCommonStyles';
import ChipListView from "../../../../Common/ChipListView";
import ReviewItemValueOrSkeleton from "./ReviewItemValueOrSkeleton";

interface Props extends ReviewCommonChildrenProps {
    specification: SpecificationEdit | undefined,
    loading: boolean;

}


const ServerReview: React.FC<Props> = ({specification, loading, elevation, onNavigateToStep}) => {
    const theme = useTheme();

    return (
        <Stack sx={{padding: 2,}} component={Paper} elevation={elevation}>
            <Stack sx={{paddingY: theme.spacing(1)}} direction={'row'} alignItems={'center'}
                   justifyContent={'space-between'}>
                <ReviewSectionLabel variant={'h6'}>Server Review</ReviewSectionLabel>
                <Button onClick={() => onNavigateToStep(EditorForms.Servers)}
                        size={'small'}
                        color={"info"}
                        endIcon={<EditOutlined/>}>
                    Edit
                </Button>
            </Stack>

            <List sx={{padding: 0}} component={Paper} variant={'outlined'}>
                <ReviewListHeader>
                    <ReviewHeaderKey>Server</ReviewHeaderKey>
                    <ReviewHeaderValue>Details</ReviewHeaderValue>
                </ReviewListHeader>

                {
                    specification?.servers?.map((server, index) => {
                        return (
                            <ReviewListItem key={index} disableAlternatingColors sx={{alignItems: 'flex-start'}}
                                            divider>
                                <ReviewItemKeyWrapper>
                                    <ReviewItemKey size={'small'} label={server.name}/>
                                </ReviewItemKeyWrapper>
                                <ReviewItemValue>
                                    <NestedReviewListItem>
                                        <ReviewItemKeyWrapper>Services</ReviewItemKeyWrapper>

                                        <ReviewItemValueOrSkeleton
                                            value={<ChipListView values={server.tags || []}/>}
                                            loading={loading}/>
                                    </NestedReviewListItem>

                                    <NestedReviewListItem>
                                        <ReviewItemKeyWrapper>Description</ReviewItemKeyWrapper>
                                        <ReviewItemValueOrSkeleton
                                            value={server.details?.description}
                                            loading={loading}/>

                                    </NestedReviewListItem>

                                    <NestedReviewListItem>
                                        <ReviewItemKeyWrapper>OS</ReviewItemKeyWrapper>
                                        <ReviewItemValueOrSkeleton
                                            value={server.details?.os}
                                            loading={loading}/>
                                    </NestedReviewListItem>

                                    <NestedReviewListItem>
                                        <ReviewItemKeyWrapper>Image</ReviewItemKeyWrapper>
                                        <ReviewItemValueOrSkeleton
                                            value={server.image}
                                            loading={loading}
                                        />
                                    </NestedReviewListItem>

                                    <NestedReviewListItem>
                                        <ReviewItemKeyWrapper>Machine Type</ReviewItemKeyWrapper>
                                        <ReviewItemValueOrSkeleton
                                            value={server.machine_type}
                                            loading={loading}/>
                                    </NestedReviewListItem>

                                    <NestedReviewListItem>
                                        <ReviewItemKeyWrapper>Nics</ReviewItemKeyWrapper>
                                        <ReviewItemValueOrSkeleton
                                            value={JSON.stringify(server.nics)}
                                            loading={loading}/>
                                    </NestedReviewListItem>
                                </ReviewItemValue>
                            </ReviewListItem>
                        );
                    })
                }

                {
                    (specification?.servers || []).length === 0 &&
                    <EmptyListItem>
                        {
                            loading ?
                                <Skeleton variant={'rounded'} height={150} width={'100%'}/>
                                :
                                <EmptyListItemText>No Servers</EmptyListItemText>
                        }
                    </EmptyListItem>
                }

            </List>


        </Stack>
    )
}

export default ServerReview;
