import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button
} from '@mui/material';
import SaveIcon from "@mui/icons-material/Save";
import {CancelRounded} from "@mui/icons-material";

interface EditDialogProps {
    title: string;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    formComponent: React.ComponentType<any>;
    formProps: any;
}

const EditFormDialog: React.FC<EditDialogProps> = (
    {
        title,
        open,
        onClose,
        onSave,
        formComponent: FormComponent,
        formProps
    }
) => {

    return (
        <Dialog open={open} onClose={onClose} disableEnforceFocus>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <FormComponent {...formProps} />
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"outlined"}
                    onClick={onClose}
                    color="secondary"
                    endIcon={<CancelRounded />}
                >
                    Cancel
                </Button>
                <Button
                    variant={"contained"}
                    onClick={onSave}
                    color="success"
                    endIcon={<SaveIcon />}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditFormDialog;
