import {AgogeImage, ImageSummaryLists} from "../../services/Server/image.model"

export const transformToAgogeImage = (data: any): AgogeImage => {
    return {
        id: data.id ?? '',
        add_disk: data.add_disk,
        description: data.description,
        disks: data.disks,
        dns_record: data.dns_record,
        human_interaction: data.human_interaction,
        image: data.image,
        in_use_by: data.in_use_by,
        machineType: data.machineType,
        name: data.name,
        os: data.os,
        self_link: data.self_link,
        services: data.services,
        state: data.state,
        state_timestamp: data.state,
        status: data.status,
        tags: data.tags,
    };
};

export const transformToAgogeImageList = (data: any[]): AgogeImage[] =>{
    return data.map(transformToAgogeImage)
}