import React, {useEffect, useState} from 'react';
import {Box, Button, Chip, Container, Paper, Snackbar, useTheme} from '@mui/material';
import {
  Build,
  CloudUpload,
  DeleteOutlined,
  FileCopy,
  KeyboardTab,
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {GridColDef, GridRenderCellParams, GridRowSelectionModel} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import {useModal} from 'mui-modal-provider';

import {useAuth} from '../../../../context/AuthContext';
import {AppUiObjectNames} from '../../../../types/AppObjectNames';
import {SpecificationStates} from '../../../../types/AgogeStates';
import {Specification, TeachingConcepts} from '../../../../services/Specification/specification.model';
import {specificationService} from '../../../../services/Specification/specification.service';
import {specificationEditService} from '../../../../services/Specification/specificationEdit.service';
import {unitService} from '../../../../services/Unit/unit.service';
import {ExpandableCell} from '../../../Common/DataGrid/ExpandableCell';
import ConfirmationDialog from '../../../Common/Confirmation';
import SimpleSnackbar from '../../../Common/SnackBar/SnackBar';
import {TagChip} from '../TagChips';
import {
  URL_TEACHER_SPECIFICATION_EDIT,
  URL_TEACHER_UNIT,
} from '../../../../router/urls';
import {ButtonConfig} from './utils/CommonConfigs';
import BuildWaitDialog from './BuildWaitDialog';
import FileUploadDialog from './FileUploadDialog';
import SpecTable from './SpecTable';
import WorkoutModal from './WorkoutModal';

const CatalogTable: React.FC = () => {
    const {showModal} = useModal();
    const { user} = useAuth();
    const [catalogList, setCatalogList] = useState<Specification[]>([]);
    const [flattenedData, setFlattenedData] = useState<any[]>([]);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [workoutModalOpen, setWorkoutModalOpen] = useState<boolean>(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
    const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState<boolean>(false);
    const [waitingModalOpen, setWaitingModalOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const theme = useTheme();

    const hasAssessment = (spec: Specification) => {
        if (spec.assessment) {
            if (spec.assessment.questions !== null){
                return "Agoge";
            }
        } else if (spec.lms_quiz) {
            return "LMS Quiz";
        }
        return "No Assessment";
    }

    useEffect(() => {
        const fetchSpecs = async () => {
            try {
                const fetchedSpecs = await specificationService.list();
                const catalogList = fetchedSpecs.filter(spec => !spec.build_type || spec.build_type === 'unit');
                setCatalogList(catalogList);
            } catch (error) {
                console.error('Error fetching specifications:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchSpecs();
    }, []);

    useEffect(() => {
        const flattenData = () => {
            const flattened = catalogList.map(item => ({
                id: item.id,
                name: item.summary?.name || 'N/A',
                description: item.summary?.description || 'N/A',
                tags: item.summary?.tags || [],
                discriminator: item.discriminator,
                lms_quiz: item.lms_quiz || null,
                assessment: item.assessment || null,
                assessment_type: hasAssessment(item),
            }));
            setFlattenedData(flattened);
        };

        flattenData();
    }, [catalogList]);

    const handleFileUploadDialogOpen = () => {
        setFileUploadDialogOpen(true);
    };

    const handleRowSelection = (selectionModel: GridRowSelectionModel) => {
        if (selectionModel.length > 0) {
            const selectedRowData = catalogList.find((row) => row.id === selectionModel[0]);
            setSelectedRow(selectedRowData);
        } else {
            setSelectedRow(null);
        }
    };

    const handleFormSubmit = async (formData: any) => {
        setWorkoutModalOpen(false);
        setWaitingModalOpen(true);
        try {
            const response = await unitService.create(formData);
            if (response?.build_id) {
                navigate(`${URL_TEACHER_UNIT}/${response.build_id}`);
            } else {
                throw new Error("Failed to redirect to unit. Received an invalid response");
            }
        } catch (error) {
            console.log(error);
            setErrorMessage('Failed to create lab.');
            setWaitingModalOpen(false);
            setWorkoutModalOpen(true);
            showModal(SimpleSnackbar, {
                message: `Failed to create lab. ${error}`,
                severity: "error",
            });
        }
    };

    const handleBuildDialog = () => {
        setWorkoutModalOpen(true);
    };

    const handleCreateNew = async () => {
        console.log('Creating new spec ...');
        try {
            let spec = await specificationEditService.create(SpecificationStates.CREATE);
            navigateToEdit(spec);
        } catch (error) {
            console.log(error);
            showModal(SimpleSnackbar, {
                message: "Failed to create specification template",
                severity: "error",
            });
        }
    };

    const handleCreateCopy = async () => {
        let specId = selectedRow.id;

        try {
            let spec = await specificationEditService.create(SpecificationStates.COPY, specId);
            navigateToEdit(spec);
        } catch (error) {
            console.log(error);
            showModal(SimpleSnackbar, {
                message: "Failed to copy specification",
                severity: "error",
            });
        }
    };

    const handleDeleteDialog = () => {
        setConfirmationDialogOpen(true);
    };

    const navigateToEdit = (spec: any) => {
        if (spec?.build_id) {
            navigate(`${URL_TEACHER_SPECIFICATION_EDIT}/${spec.build_id}`);
        } else {
            console.log("Received response but could not process data", spec);
        }
    };

    const handleConfirmDelete = async () => {
        if (!selectedRow) {
            showModal(SimpleSnackbar, {
                message: "You must select a specification first",
                severity: "error"
            });
            setConfirmationDialogOpen(false);
            return;
        }

        try {
            await specificationService.del(selectedRow.id);
            setCatalogList(prevList => prevList.filter(item => item.id !== selectedRow.id));
            setFlattenedData(prevData => prevData.filter(item => item.id !== selectedRow.id));
            showModal(SimpleSnackbar, {
                message: "Specification deleted successfully.",
                severity: "success"
            });
        } catch (error) {
            console.error('Error deleting specification:', error);
            showModal(SimpleSnackbar, {
                message: "Failed to delete specification.",
                severity: "error"
            });
        } finally {
            setSelectedRow(null);
            setConfirmationDialogOpen(false);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            filterable: true,
            sortable: true,
            renderCell: (params: any) => (
                <ExpandableCell {...params} value={params.value} variant={"body1"} />
            ),
        },
        {
            field: 'discriminator',
            headerName: 'Discriminator',
            sortable: false,
            filterable: false,
            renderCell: (cellValues: any) => (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    overflowY: 'auto',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                }}
                >
                    <Chip
                        variant={"outlined"}
                        label={`#${cellValues.row.discriminator}`}
                        sx={{
                            borderColor: theme.palette.primary.light,
                            borderRadius: "4px",
                        }}
                    />
                </Box>
            )
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 400,
            filterable: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <ExpandableCell
                    {...params}
                    value={params.value || 'No lab description'}
                    variant={"body1"}
                />
            ),
        },
        {
            field: 'tags',
            headerName: "Tags",
            filterable: false,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <ExpandableCell
                    {...params}
                    value={params.value.map((tag: TeachingConcepts) => (
                        <TagChip key={tag.id} chipType={tag.name} />
                    ))}
                />
            ),
        },
        {
            field: 'assessment_type',
            headerName: 'Assessment',
            flex: 1,
            filterable: true,
            sortable: true,
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    {params.value && params.value !== 'No Assessment' ? (
                        <Chip variant={"outlined"} label={params.value} color="success" />
                    ) : (
                        <Chip variant={"outlined"} label={params.value} color="error" />
                    )}
                </div>
            ),
        },
    ];

    const getButtonConfig = () => {
        const buttonConfig: ButtonConfig[] = [
            {
                label: "Create New Template",
                variant: 'contained',
                color: "info",
                icon: <AddIcon />,
                requiresSelection: false,
                addTooltip: true,
                tooltip: "Create a new specification to use in future labs",
                onClick: handleCreateNew
            },
            {
                label: "Upload New Template",
                variant: 'outlined',
                color: "success",
                icon: <CloudUpload />,
                requiresSelection: false,
                addTooltip: true,
                tooltip: "Upload a new specification to use in future labs",
                onClick: handleFileUploadDialogOpen
            },
            {
                label: "Build Lab",
                variant: 'outlined',
                color: "info",
                requiresSelection: true,
                icon: <Build />,
                addTooltip: true,
                tooltip: "Build a lab using selected specification",
                onClick: handleBuildDialog
            },
            {
                label: "Create Copy",
                variant: 'outlined',
                color: "info",
                requiresSelection: true,
                icon: <FileCopy />,
                addTooltip: true,
                tooltip: "Copy the selected specification for editing",
                onClick: handleCreateCopy
            },
        ]

        if (user?.permissions.admin) {
            buttonConfig.push({
                    label: "Delete",
                    variant: 'outlined',
                    color: "error",
                    requiresSelection: true,
                    icon: <DeleteOutlined />,
                    addTooltip: true,
                    tooltip: "Delete the selected specification from the catalog",
                    onClick: handleDeleteDialog
                },
            )
        }
        return buttonConfig;
    }


    return (
        <>
            <Container maxWidth={"lg"}>
                <Paper
                    sx={{
                        mt: 10,
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        padding: 1,
                    }}
                    square={false}
                >
                    <Box mb={2}>
                        <Button
                            variant={"outlined"}
                            color={"info"}
                            href={URL_TEACHER_SPECIFICATION_EDIT}
                            sx={{ mt: 2, ml: 2 }}
                            endIcon={<KeyboardTab />}
                        >
                            View Existing Catalog Edits
                        </Button>
                    </Box>
                    <SpecTable
                        data={flattenedData}
                        columns={columns}
                        disableMultiRowSelection={true}
                        disableSelectBtn={false}
                        label={`${AppUiObjectNames.UNIT} Catalog`}
                        loading={loading}
                        buttonsConfig={getButtonConfig()}
                        onSelection={handleRowSelection}
                        selectedRow={selectedRow}
                    />
                </Paper>
            </Container>

            {/* Generate Page Modals */}
            <FileUploadDialog
                isOpen={fileUploadDialogOpen}
                onClose={() => setFileUploadDialogOpen(false)}
            />
            <BuildWaitDialog
                open={waitingModalOpen}
                onClose={() => setWaitingModalOpen(false)}
            />
            {selectedRow && (
                <>
                    <WorkoutModal
                        open={workoutModalOpen}
                        user={user}
                        onClose={() => setWorkoutModalOpen(false)}
                        onSubmit={handleFormSubmit}
                        selectedRow={selectedRow}
                    />
                    <ConfirmationDialog
                        open={confirmationDialogOpen}
                        handleClose={() => setConfirmationDialogOpen(false)}
                        handleConfirm={handleConfirmDelete}
                        expectedText={selectedRow?.id}
                    />
                </>
            )}
        </>
    );
};

export default CatalogTable;
