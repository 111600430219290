import React from 'react';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import '../../../styles/theme';
import { useAuth } from '../../../context/AuthContext';
import ActiveExpiredUnitTable from "../Home/ActiveExpiredUnitTable";

const TeacherHome: React.FC = () => {
    const { user, currentUser } = useAuth();

    useEffect(() => {}, [currentUser, user]);

    return (
        <Box id="homePage" sx={{mt: 10}}>
            <ActiveExpiredUnitTable />
        </Box>
    );
};

export default TeacherHome;
