import React, { useState, useEffect } from "react";
import {
    Button,
    Box,
    Stack,
    styled,
    Theme,
    Typography,
    useTheme,
    Skeleton, Tooltip,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {useModal} from "mui-modal-provider";

import {ButtonConfig} from "./utils/CommonConfigs";
import {shouldCellShowRightBorder} from "@mui/x-data-grid/internals";

interface DataGridProps {
    data: any[];
    columns: any[];
    sx?: SxProps<Theme>;
    loading?: boolean;
    label?: string;
    labelProps?: SxProps<Theme>;
    disableSelectBtn?: boolean;
    disableMultiRowSelection?: boolean;
    onSelection?: (value: any) => void;
    selectedRow?: any;
    pageSize?: 10 | 25 | 50 | 100;
    buttonsConfig?: ButtonConfig[];
    showCellBorder?: boolean;
}

const SpecDataGrid: React.FC<DataGridProps> = (props: DataGridProps) => {
    const { showModal } = useModal();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[]>(props.data);

    const theme = useTheme();

    useEffect(() => {
        setFilteredData(
            props.data.filter(row =>
                Object.values(row).some(value => String(value).toLowerCase().includes(searchQuery.toLowerCase()))
            )
        );
    }, [props.data, searchQuery]);

    const renderButtons = () => {
        return props.buttonsConfig?.map((button, index) => {
            const buttonElement = (
                <Button
                    key={index}
                    variant={button.variant}
                    color={button.color || 'primary'}
                    onClick={() => button.onClick()}
                    disabled={button.requiresSelection && !props.selectedRow}
                    startIcon={button.icon}
                    sx={{ mx: 2 }}
                >
                    {button.label}
                </Button>
            );

            if (button.addTooltip && button.tooltip) {
                return (
                    <Tooltip key={index} title={button.tooltip}>
                        <span>
                            {buttonElement}
                        </span>
                    </Tooltip>
                );
            }

            return buttonElement;
        });
    };

    const render = () => {
        if (props.loading) {
            return (
                <Skeleton variant={"rectangular"} width="100%" height={300} />
            );
        } else {
            return (
                <DataGrid
                    sx={{
                        ...props.sx,
                        minHeight: 400,
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                    }}
                    checkboxSelection={true}
                    rows={props.data}
                    columns={props.columns}
                    loading={props.loading}
                    filterDebounceMs={60}
                    disableMultipleRowSelection={props.disableMultiRowSelection ?? false}
                    disableRowSelectionOnClick={false}
                    disableDensitySelector={true}
                    disableColumnFilter={false}
                    density={"comfortable"}
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 100}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 250 },
                        },
                    }}
                    onRowSelectionModelChange={props.onSelection}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10
                            }
                        }
                    }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    showCellVerticalBorder={props.showCellBorder}
                />
            );
        }
    };

    return (
        <>
            <Stack
                alignItems={"center"}
                direction={"row"}
                gap={1}
                paddingTop={1}
            >
                <AssignmentIcon fontSize={"large"} />
                <Typography
                    variant={"h4"}
                    component={"h2"}
                    sx={{
                        fontFamily: "monospace",
                        letterSpacing: 2
                    }}
                >
                    {props.label}
                </Typography>
            </Stack>
            <Box sx={{ py: 2 }}>
                {renderButtons()}
            </Box>
            {render()}
        </>
    );
};

export default SpecDataGrid;
