import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { apiService } from '../../../services/api-request.service';
import { MarkdownRenderer } from './Markdown';
import FileStreamProps from './fileProps';
import { URL_DOCS_API_BASE } from '../../../router/urls';

const FileStream: React.FC<FileStreamProps> = ({ buildId, fileType }) => {
    const [fileContent, setFileContent] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const fetchFile = async () => {
        try {
            const response = await apiService.get(
                `${URL_DOCS_API_BASE}/student/${buildId}`,
                null,
                true,
                (data: BlobPart) => {
                    return new Blob(
                        [data],
                        { type: fileType === 'markdown' ? 'text/markdown' : 'application/pdf' }
                    );
                }
            );

            if (fileType === 'markdown') {
                const text = await response.text();
                setFileContent(text);
            } else if (fileType === 'pdf') {
                const url = URL.createObjectURL(response);
                setFileContent(url);
            }
        } catch (err: unknown) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('An unknown error occurred while fetching the file');
            }
        }
    };

    useEffect(() => {
        fetchFile();
    }, []);

    return (
        <div>
            {error && <p>Error: {error}</p>}

            {fileType === 'markdown' ? (
                fileContent ? (
                    <div style={{ width: "90%" }}>
                        <MarkdownRenderer>{fileContent}</MarkdownRenderer>
                    </div>
                ) : (
                    <Skeleton
                        variant="rounded"
                        animation={"wave"}
                        width="100%"
                        height={400}
                    />
                )
            ) : (
                fileType === 'pdf' && (
                    fileContent ? (
                        <div>
                            <object
                                data={fileContent}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                            >
                                <p>
                                    Alternative link
                                    <a href={fileContent} target="_blank" rel="noopener noreferrer"> to the PDF</a>
                                </p>
                            </object>
                        </div>
                    ) : (
                        <Skeleton
                            variant="rounded"
                            animation={"wave"}
                            width="100%"
                            height={600}
                        />
                    )
                )
            )}
        </div>
    );
};

export default FileStream;
