import React from 'react';
import {Box, Paper, Typography, Button} from '@mui/material';
import DataTable from "../Common/DataTable";
import { WorkoutConnectButton } from "../Buttons/ConnectButton/WorkoutConnectButton";
import { WorkoutFull } from '../../services/Workout/workout.model';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface WorkoutTableProps {
    workoutFull: WorkoutFull;
}

const WorkoutTable: React.FC<WorkoutTableProps> = ({ workoutFull }) => {
    const servers = workoutFull?.servers?.filter(server => !server.dns_hostname) || [];
    const sortedServers = servers.sort((a, b) => {
        const nameA = a.name?.toString() || '';
        const nameB = b.name?.toString() || '';
        return nameA.localeCompare(nameB);
    });
    const processedServers = sortedServers.map((server: any, index: number) => ({
        ...server,
        index,
        internal_ip: server.nics?.[0]?.internal_ip || '',
        state: workoutFull?.workout.state,
    }));

    const columns = [
        {
            field: 'connect',
            headerName: 'Connection',
            minWidth: 175,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: any) => (
                <WorkoutConnectButton
                    item={params.row}
                    buildId={workoutFull.workout.id}
                    serverIdx={params.row.index.toString()}
                    addGuacamole={true}
                />
            )
        },
        {
            field: 'name',
            headerName: 'Server Name',
            minWidth: 175,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'hostname',
            headerName: 'Host',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'internal_ip',
            headerName: 'IP',
            headerAlign: 'center',
            align: 'center'
        }
    ];
    return (
        <Box sx={{ width:"100%", display: 'flex', flexDirection: 'row'}}>
            <Box sx={{ width: workoutFull.workout.web_applications ? "65%": "100%" }}>
                <Paper elevation={1} sx={{p: 1}}>
                    <DataTable
                        title={
                            <Typography variant={"h5"} component={"h2"}>
                                Servers
                            </Typography>
                        }
                        data={processedServers}
                        columns={columns}
                        showSearch={false}
                        checkboxSelection={false}
                        getRowId={(row) => row.name}
                        showContainer={true}
                    />
                </Paper>
            </Box>
            {workoutFull.workout?.web_applications && (
                <Box sx={{width:"35%",justifyContent:"center", display:'flex', ml:2, mr:2}}>
                    <Paper
                        elevation={1}
                        sx={{
                            width:"100%",
                            justifyContent:'center',
                            display:'flex',
                            alignItems:'center',
                            flexDirection:'column'
                        }}
                        >
                        <Typography
                            variant="h5"
                            color="textPrimary"
                            component="div"
                            sx={{mb:4}}
                        >
                            Web App
                        </Typography>
                        {workoutFull.workout.web_applications.map((app,index) => (
                            <Button
                                variant="contained"
                                color="primary"
                                target={"_blank"}
                                href={app.url as unknown as string}
                                sx={{mb: 2}}
                                endIcon={<OpenInNewIcon />}
                            >
                                {app.name || "Web App"}
                            </Button>
                        ))}
                    </Paper>
                </Box>
            )}
        </Box>
    );
};

export default WorkoutTable;
