import React, {useState} from "react";
import {Snackbar, useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Clear} from "@mui/icons-material";
import {lighten} from "@mui/system";
import Alert from '@mui/material/Alert'

interface SnackbarProps {
    message: string | React.ReactNode,
    open: boolean,
    onClose?: Function,
    disableAutoClose?: boolean,
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
    severity?: 'info' | 'warning' | 'error' | 'success';
}

export default function SimpleSnackbar(props: SnackbarProps) {
    const theme = useTheme();

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        props.onClose && props.onClose()
    };

    const action = (
        <React.Fragment>
            <IconButton color="error" size="small" onClick={handleClose}>
                <Clear/>
            </IconButton>
        </React.Fragment>
    );


    return (
        <Snackbar
            open={props.open}
            anchorOrigin={{
                vertical: props.vertical ? props.vertical : 'bottom',
                horizontal: props.horizontal ? props.horizontal : 'center'
            }}
            autoHideDuration={props.disableAutoClose ? null : 5000}
            onClose={handleClose}
            ContentProps={{
                style: {
                    fontWeight: 600,
                    letterSpacing: '.025rem',
                    color: theme.palette.text.primary, // This will be a dark color if your theme is light.
                }
            }}
            action={action}
        >
            <Alert sx={{
                width: '100%',
                background: lighten(theme.palette.background.paper, .15),
                borderLeft: `3px solid ${theme.palette.info.main}`,
                display: 'flex',
                alignItems: 'center'
            }} severity={props.severity ? props.severity : 'info'}>
                {props.message}
            </Alert>
        </Snackbar>
    );
}