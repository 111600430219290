import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, useTheme } from '@mui/material';
import {LoadingButton} from "@mui/lab";

interface ConfirmationDialogProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    expectedText: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, handleClose, handleConfirm, expectedText }) => {
    const [inputValue, setInputValue] = useState('');
    const theme = useTheme();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const isConfirmEnabled = inputValue === expectedText;

    useEffect(() => {
        if (!open) {
            setInputValue('');
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ color: theme.palette.text.primary }}>Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: theme.palette.text.primary }}>
                    Are you sure you want to delete the selected items? This action cannot be undone.
                </DialogContentText>
                <DialogContentText sx={{ color: theme.palette.text.primary }}>
                    Please type <strong>{expectedText}</strong> to confirm.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Confirmation"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={inputValue}
                    onChange={handleInputChange}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    sx={{
                        '& .MuiInputLabel-root': {
                            color: theme.palette.text.primary,
                        },
                        '& .MuiInputBase-input': {
                            color: theme.palette.text.primary,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottomColor: theme.palette.text.primary,
                        },
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} sx={{ color: theme.palette.text.primary }}>Cancel</Button>
                <LoadingButton
                    onClick={handleConfirm}
                    sx={{
                        color: isConfirmEnabled ? theme.palette.error.main : theme.palette.text.primary,
                        '&.Mui-disabled': {
                            color: theme.palette.text.disabled,
                        },
                    }}
                    disabled={!isConfirmEnabled}
                >
                    Delete
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
