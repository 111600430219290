import React from "react";
import {
    Box,
    Divider,
    IconButton,
    Link,
    Typography
} from "@mui/material";
import {ServerStates} from "../../../types/AgogeStates";
import {BaseConnectButton, ConnectButtonProps} from "./BaseConnectButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useClipboard} from "../../../hooks/useClipboard";


export const ServerConnectButton: React.FC<ConnectButtonProps> = ({ item }) => {
    const {copy} = useClipboard();
    const parsedHumanInteraction = JSON.parse(item.human_interaction);
    const protocol = parsedHumanInteraction.protocol;

    const isDisabled = () => item.state === ServerStates.START || item.state !== ServerStates.RUNNING;

    const renderDetails = (item: any) => {
        return (
            <>
                {protocol === 'rdp' && (
                    <>
                        <Typography id="rdp-connect-modal-description" sx={{ mt: 2 }} variant="h6">
                            RDP
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            To connect to this server, connect using your system's RDP app with the server details provided below.
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            <ul>
                                <li>On Windows, this is Remote Desktop Connection</li>
                                <li>
                                    On Mac, it is Microsoft Remote Desktop (
                                    <Link href="https://apps.apple.com/us/app/microsoft-remote-desktop/id1295203466" target="_blank" rel="noopener">
                                        Download here
                                    </Link>
                                    )
                                </li>
                            </ul>
                        </Box>
                        <Divider />
                        <Box component="section" paddingTop={1}>
                            <Typography>Hostname</Typography>
                            <Box
                                component="section"
                                sx={{
                                    p: 1,
                                    border: '1px solid grey',
                                    borderRadius: '4px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        mt: 1,
                                        wordBreak: 'break-all',
                                        background: 'background.paper',
                                        borderRadius: '4px'
                                    }}
                                >
                                    <em>{item.dns_record}</em>
                                    <IconButton onClick={() => copy(item.dns_record)} sx={{ ml: 1 }}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Typography>
                            </Box>
                            <Typography sx={{ mt: 2 }}>Username</Typography>
                            <Box
                                component="section"
                                sx={{
                                    p: 1,
                                    border: '1px solid grey',
                                    borderRadius: '4px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        mt: 1,
                                        wordBreak: 'break-all',
                                        background: 'background.paper',
                                        borderRadius: '4px'
                                    }}
                                >
                                    <em>{parsedHumanInteraction.username}</em>
                                    <IconButton onClick={() => copy(parsedHumanInteraction.username)} sx={{ ml: 1 }}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Typography>
                            </Box>

                            <Typography sx={{ mt: 2 }}>Password</Typography>
                            <Box
                                component="section"
                                sx={{
                                    p: 1,
                                    border: '1px solid grey',
                                    borderRadius: '4px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        mt: 1,
                                        wordBreak: 'break-all',
                                        background: 'background.paper',
                                        borderRadius: '4px'
                                    }}
                                >
                                    <em>{parsedHumanInteraction.password}</em>
                                    <IconButton onClick={() => copy(parsedHumanInteraction.password)} sx={{ ml: 1 }}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Typography>
                            </Box>
                        </Box>
                    </>
                )}
                {protocol === 'ssh' && (
                    <>
                        <Typography id="ssh-connect-modal-description" sx={{ mt: 2 }} variant="h6">
                            SSH
                        </Typography>
                        <Typography sx={{ mt: 2, mb: 2 }}>
                            On your machine, open up a terminal/command prompt and run the following command
                            and enter the password listed below when prompted.
                        </Typography>
                        <Divider />
                        <Box sx={{ mt: 2 }}>
                            <Typography>Command:</Typography>
                            <Box
                                component="section"
                                sx={{
                                    p: 1,
                                    border: '1px solid grey',
                                    borderRadius: '4px'
                                }}
                            >
                                {item.dns_record && (
                                    <>
                                        <Typography >
                                            <em>ssh {parsedHumanInteraction.username}@{item.dns_record}</em>
                                            <IconButton
                                                onClick={() => copy(`ssh ${parsedHumanInteraction.username}@${item.dns_record}`)}
                                                sx={{ ml: 1 }}
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Typography>

                                    </>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography>User Password:</Typography>
                            <Box
                                component="section"
                                sx={{
                                    p: 1,
                                    border: '1px solid grey',
                                    borderRadius: '4px'
                                }}
                            >
                                <Typography sx={{ mt: 1, wordBreak: 'break-all' }}>
                                    <em>{parsedHumanInteraction.password}</em>
                                    <IconButton
                                        onClick={() => copy(parsedHumanInteraction.password)}
                                        sx={{ ml: 1 }}
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Typography>
                            </Box>
                        </Box>

                    </>
                )}
            </>
        );
    };

    return <BaseConnectButton item={item} isDisabled={isDisabled} renderDetails={renderDetails} />;
};