import React, {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Box, Button, Paper, Stack, Typography, useTheme} from "@mui/material";
import {lighten} from "@mui/system";
import {LoadingButton} from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import {
    FormFields,
    FormGroup,
    StyledFormFieldLabel,
} from "../NewEditor/utils/FormFields";
import {specificationService} from "../../../../services/Specification/specification.service";

interface Props {
    isOpen: boolean
    onClose: (name: string | null) => void
}

const FileUploadDialog: React.FC<Props> = ({isOpen, onClose}) => {
    const [submitting, setSubmitting] = useState(false);
    const [fileObject, setFileObject] = useState<File | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    let fileInput: HTMLInputElement | null = null;

    const [filenameInput, setFilenameInput] = useState<{ value: string, error: null | string }>({
        value: '',
        error: null
    })

    const [selectedFileInput, setSelectedFileInput] = useState<{
        value: any;
        error: string | null;
        dragging: boolean;
    }>({
        value: null,
        error: 'Required',
        dragging: false
    });

    useEffect(() => {
        setSelectedFileInput({...selectedFileInput, error: validateFileInput(selectedFileInput.value)})
    }, [filenameInput.value]);

    useEffect(() => {}, [fileObject]);

    const handleSubmit = async () => {
        setSubmitting(true);

        const file = selectedFileInput.value;

        try {
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                await specificationService.upload(formData);
                setSnackbarMessage("Successfully uploaded file!");
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage("Failed to upload file.");
            setSnackbarOpen(true);
            setSubmitting(false);
        }
    }

    const fileExtensionValidator = (fileName: string): string | null => {
        const regexExtension = /^[a-zA-Z0-9\-]{1,63}\.(yaml|yml|json)$/;

        if (!regexExtension.test(fileName)) {
            return "File name must end with a valid file extension.";
        }

        return null;
    };

    const validateFileInput = (value: File | null) => {
        if (value == null) {
            return 'Required'
        }

        if (value && !filenameInput.value) {
            return fileExtensionValidator(value.name);
        }

        return null;
    }

    const handleDrop = (e: any) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        setSelectedFileInput({value: file, dragging: false, error: validateFileInput(file)})
    }

    const handleDragOver = (e: any) => {
        e.preventDefault();
        if (!selectedFileInput.dragging) {
            setSelectedFileInput({...selectedFileInput, dragging: true})
        }
    }

    const handleDragLeave = (e: any) => {
        e.preventDefault();
        setSelectedFileInput({...selectedFileInput, dragging: false})
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setSelectedFileInput({...selectedFileInput, value: files[0], error: validateFileInput(files[0])})
        }
    };

    const theme = useTheme();

    return (
        <Dialog open={isOpen} maxWidth={'sm'} fullWidth>
            <DialogTitle>Upload Lab Specification</DialogTitle>
            <DialogContent>
                <FormFields>
                    <FormGroup>
                        <Stack direction={'row'} gap={1}>
                            <StyledFormFieldLabel>Lab Specification</StyledFormFieldLabel>
                            {selectedFileInput.value && (
                                <StyledFormFieldLabel
                                    sx={{color: theme.palette.success.main}}>(Uploaded)</StyledFormFieldLabel>
                            )}
                        </Stack>
                        <Stack gap={1}>
                            <Paper elevation={8}>
                                <Box component={Paper}
                                     onDragOver={(e: any) => handleDragOver(e)}
                                     onDragLeave={(e: any) => handleDragLeave(e)}
                                     elevation={1}
                                     onClick={() => fileInput?.click()}
                                     onDrop={(e: any) => handleDrop(e)}
                                     sx={{
                                         border: '2px dashed ' + (!(!!selectedFileInput.error) ? 'gray' : theme.palette.error.main),
                                         width: '200px%',
                                         height: '200px',
                                         cursor: "pointer",
                                         "&:hover": {
                                             background: (theme) =>
                                                 lighten(theme.palette.background.default, 0.15),
                                         },
                                         display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'center',
                                         color: selectedFileInput.dragging ? 'green' : 'black'
                                     }}
                                >
                                    {selectedFileInput.value?.name ?
                                        <Typography color={'text.secondary'} className={'overflow'}>
                                            {selectedFileInput.value.name}
                                        </Typography> :
                                        <Typography color={'text.secondary'}>
                                            Click or Drag a file here
                                        </Typography>}
                                    <input
                                        type="file"
                                        style={{display: 'none'}}
                                        ref={(input) => (fileInput = input)}
                                        onChange={(e) => handleFileChange(e)}
                                    />
                                </Box>
                            </Paper>
                            {
                                !!selectedFileInput?.error &&
                                <Typography sx={{ml: 1.5}} variant={'caption'} color={'text.secondary'}>
                                    {selectedFileInput.error}
                                </Typography>
                            }
                        </Stack>
                    </FormGroup>
                </FormFields>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(null)}>Cancel</Button>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={handleSubmit}
                    loading={submitting}
                    loadingPosition="end"
                    endIcon={<SaveIcon/>}
                    variant="contained"
                    disabled={selectedFileInput.error !== null || filenameInput.error !== null}>
                    Upload
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default FileUploadDialog;
