import { createTheme } from "@mui/material/styles";

// Define the colors
const primaryMain = '#00518c';
const primaryLight = '#3379a4';
const primaryDark = '#01477b';  // '#003a63';
const secondaryMain = '#f50057';
const secondaryDark = '#9b0339';
const primaryBackgroundLight = '#dad6d6';
const secondaryBackgroundLight = '#dad6d6';


export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: primaryMain,
            light: primaryLight,
            dark: primaryDark,
            contrastText: '#fff',
        },
        secondary: {
            main: secondaryMain,
            dark: secondaryDark,
            contrastText: '#fff',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0bec5',
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                color: "inherit",
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: "info"
            }
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: primaryMain,
                },
                colorSecondary: {
                    backgroundColor: primaryMain,
                    color: "#ffffff",
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-indicator': {
                        backgroundColor: primaryLight,
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: "white",
                        borderRadius: "2px",
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: 'rgb(5, 119, 203)',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    'input:-webkit-autofill': {
                        WebkitBoxShadow: "0 0 0px 1000px rgb(51 50 50 / 98%) inset",
                        WebkitTextFillColor: '#ffffff',
                    },
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#90caf9',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: '#90caf9',
                    },
                },
            },
        },
    }
});

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: primaryMain,
            light: primaryLight,
            dark: primaryDark,
            contrastText: '#fff'
        },
        secondary: {
            main: secondaryMain,
            dark: secondaryDark,
            contrastText: '#fff',
        },
        background: {
            default: '#dad6d6',
            paper: '#f0eded',
        },
        text: {
            primary: '#000000',
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                color: "inherit",
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: primaryDark,
                },
                colorSecondary: {
                    backgroundColor: primaryDark,
                    color: "#ffffff",
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-indicator': {
                        backgroundColor: primaryLight,
                    }
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    'input:-webkit-autofill': {
                        WebkitBoxShadow: `0 0 0px 1000px 'rgba(232, 240, 254, 1)' inset`,
                        WebkitTextFillColor: 'black',
                    },
                }
            }
        }
    }
});
