import React, { useState, useEffect } from 'react';
import {Paper, Typography, Box, useTheme, Divider, ChipProps} from '@mui/material';
import { ExtendButton, StartButton, StopButton } from '../Buttons/ControlButtons';
import { WorkoutStates } from "../../types/AgogeStates";
import NumberInputField from "./FormInputs/NumberInputField";
import {AppUiObjectNames} from "../../types/AppObjectNames";
import {StateInfo} from "./Status/StateInfo";


interface TimerBoxProps {
    type: 'unit' | 'workout';
    build_id: string;
    unitData?: {
        workspace_settings?: {
            expires?: number;
        };
    };
    workoutData?: {
        expiration: number;
        state: string;
        shutoff_timestamp?: number;
        proxy_connections?: any;
    };
}

interface StatusInfo {
    text: string;
    color: string;
}

const stateMapping: { [key in WorkoutStates]?: StatusInfo } = {
    [WorkoutStates.NOT_BUILT]: { text: "Not Built", color: '#b0b0b0' },
    [WorkoutStates.RUNNING]: { text: "Running", color: '#388e3c' },
    [WorkoutStates.STOPPING]: { text: "Stopping", color: '#ada500' },
    [WorkoutStates.READY]: { text: "Stopped", color: '#42a5f5' },
    [WorkoutStates.STARTING]: { text: "Starting", color: '#ada500' },
    [WorkoutStates.EXPIRED]: { text: "Expired", color: '#cccccc' },
    [WorkoutStates.BROKEN]: { text: "Broken", color: '#ff1744' },
    [WorkoutStates.DELETED]: { text: "Deleted", color: '#000000' },
};

const defaultState: StatusInfo = { text: "Working", color: '#b0b049' };

const TimerBox: React.FC<TimerBoxProps> = ({ type, build_id, unitData, workoutData }) => {
    const [days, setDays] = useState('0');
    const [duration, setHours] = useState('1');
    const [time, setTime] = useState(workoutData?.shutoff_timestamp || 0);
    const [expiresTimestamp, setExpiresTimestamp] = useState(unitData?.workspace_settings?.expires || workoutData?.expiration || 0);

    const currentDate = new Date();
    const expiryDate = new Date(expiresTimestamp * 1000);
    const isExpired = expiryDate < currentDate;
    const theme = useTheme();

    const formattedDate = isExpired
        ? "EXPIRED"
        : expiryDate.toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

    useEffect(() => {
        if (workoutData?.shutoff_timestamp) {
            const shutoffTime = Math.floor(workoutData.shutoff_timestamp - Date.now() / 1000);
            setTime(shutoffTime > 0 ? shutoffTime : 0);
        }
    }, [workoutData]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const handleDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDays(event.target.value);
    };

    const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHours(event.target.value);
    };

    const increaseUnitTimeByDays = (additionalDays: number) => {
        const newExpiresTimestamp = expiresTimestamp + additionalDays * 24 * 60 * 60;
        setExpiresTimestamp(newExpiresTimestamp);
    };

    const increaseTimeByOneHour = () => {
        setTime(prevTime => prevTime + 60 * 60);
    };

    const formatTime = (seconds: number) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const workoutState = workoutData?.state ? stateMapping[workoutData.state as unknown as WorkoutStates] || defaultState : defaultState;

    const stateColor = (): ChipProps['color'] => {
        const state = workoutData?.state.toString() || '0';

        const stateColorMap: Record<string, ChipProps['color']> = {
            [WorkoutStates.READY.toString()]: "primary",
            [WorkoutStates.RUNNING.toString()]: "success",
            [WorkoutStates.NOT_BUILT.toString()]: "default",
            [WorkoutStates.DELETED.toString()]: "default",
            [WorkoutStates.EXPIRED.toString()]: "default",
            [WorkoutStates.BROKEN.toString()]: "error",
            [WorkoutStates.MISFIT.toString()]: "error",
        };

        return stateColorMap[state] || "warning";
    };

    const isLoading = () => {
        const state = Number(workoutData?.state) || 0;

        if (state === WorkoutStates.READY || state === WorkoutStates.NOT_BUILT) {
            return false;
        }

        return (
            (state > WorkoutStates.READY && state < WorkoutStates.RUNNING) ||
            (state > WorkoutStates.RUNNING && state !== WorkoutStates.BROKEN && state !== WorkoutStates.DELETED)
        );
    };



    const renderUnitTimer = () => {
        return (
            <>
                <Typography variant="h5" component="div" gutterBottom>
                    {!isExpired ? 'Expires' : `${AppUiObjectNames.UNIT}`}: {formattedDate}
                </Typography>
                {!isExpired && (
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <NumberInputField
                            size="small"
                            label="Extend By (days)"
                            min={1}
                            max={60}
                            value={days}
                            onChange={handleDaysChange}
                        />
                        <ExtendButton
                            text="Extend Expiration"
                            use="assignment"
                            data={{ id: build_id, days: parseInt(days, 10) }}
                            additionalOnClick={() => increaseUnitTimeByDays(parseInt(days, 10))}
                        />
                    </Box>
                )}
            </>
        );
    }


    const renderWorkoutTimer = () => {
        return (
            <>
                <Typography variant="h5" component="div" gutterBottom>
                    {!isExpired ? 'Expires' : `${AppUiObjectNames.WORKOUT}`}: {formattedDate}
                </Typography>
                {workoutData ? (
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        {workoutData.proxy_connections && workoutState.text === 'Stopped' && (
                            <>
                                <NumberInputField
                                    size="small"
                                    label="Duration (hours)"
                                    min={1}
                                    max={10}
                                    value={duration}
                                    onChange={handleHoursChange}
                                />
                                <StartButton
                                    text="Start Workout"
                                    use="workout"
                                    data={{ id: build_id, duration: parseInt(duration, 10) }}
                                />
                            </>
                        )}
                        {workoutData.proxy_connections && workoutState.text === 'Running' && (
                            <>
                                <Box
                                    sx={{
                                        mt: 2,
                                        padding: '10px',
                                        borderRadius: '4px',
                                        backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#dcdcdc',
                                        color: theme.palette.mode === 'dark' ? '#dedcdc' : '#000000',
                                        display: 'inline-block',
                                        fontFamily: 'monospace',
                                        fontSize: '24px',
                                        textAlign: 'center'
                                    }}
                                >
                                    {formatTime(time)}
                                </Box>
                                <Typography
                                    color={theme.palette.text.disabled}
                                    variant="caption"
                                    m="auto"
                                >
                                    Time Remaining
                                </Typography>
                                <Divider sx={{ my: 1 }} />
                                <ExtendButton
                                    text="Add one hour"
                                    use="workout"
                                    data={{ id: build_id, hours: 1 }}
                                    additionalOnClick={increaseTimeByOneHour}
                                />
                                <StopButton
                                    text="Stop Workout"
                                    use="workout"
                                    data={{ id: build_id }}
                                />
                            </>
                        )}
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                            <StateInfo
                                label={workoutState.text}
                                variant={"filled"}
                                color={stateColor()}
                                loading={isLoading()}
                            />
                        </Box>
                    </Box>
                ) : null}
            </>
        );
    };


    return (
        <Paper elevation={1} sx={{
            padding: '16px',
            marginBottom: '16px',
            backgroundColor: 'background.paper',
            color: 'text.primary'
        }}>
            {type === 'unit' && (
                renderUnitTimer()
            )}
            {type === 'workout' && (
                renderWorkoutTimer()
            )}
        </Paper>
    );
};

export default TimerBox;
