import React, { ChangeEvent } from 'react';
import {
    AppBar,
    Toolbar,
    Tooltip,
    Typography,
    IconButton,
    Box,
} from '@mui/material';

import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ComputerIcon from '@mui/icons-material/Computer';
import Logout from '@mui/icons-material/Logout';
import {AdminPanelSettings, Build, LoginRounded, ManageAccounts} from "@mui/icons-material";
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Settings from '@mui/icons-material/Settings';

import AppBarMenu from "./AppBarMenu";
import { useAuth } from '../../../context/AuthContext';
import {
    URL_TEACHER_HOME,
    URL_TEACHER_SERVERS,
    URL_TEACHER_SETTINGS,
    URL_TEACHER_SPECIFICATIONS_BASE,
    URL_LOGIN,
    URL_ADMIN_MANAGE_USERS,
    URL_ADMIN_BASE
} from "../../../router/urls";
import { AppUiObjectNames, Users } from "../../../types/AppObjectNames";

interface AgogeAppBarProps {
    onSidebarToggle: () => void;
    toggleTheme: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    isDarkMode: boolean;
}

const AgogeAppBar: React.FC<AgogeAppBarProps> = ({ onSidebarToggle, toggleTheme, isDarkMode }) => {
    const { currentUser, user, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogin = () => {
        sessionStorage.setItem('redirectAfterLogin', location.pathname);
        navigate(URL_LOGIN);
    };

    const handleLogout = async () => {
        try {
            await logout();
        } catch (error) {
            console.error('Failed to log out:', error);
        }
    };

    const handleToggleTheme = () => {
        toggleTheme({} as ChangeEvent<HTMLInputElement>, !isDarkMode);
    };

    const renderTeacherMenus = () => {
        if (currentUser && user && user.permissions.instructor) {
            return (
                <>
                    <AppBarMenu
                        iconText={`${Users.TEACHER.toString()}s`}
                        textOverflow={false}
                        menuItems={[
                            {
                                label: `Manage ${AppUiObjectNames.UNIT}s`,
                                icon: <HomeIcon fontSize="small" />,
                                to: URL_TEACHER_HOME
                            },
                            {
                                label: `New ${AppUiObjectNames.UNIT.toString()}`,
                                icon: <Build fontSize="small" />,
                                to: URL_TEACHER_SPECIFICATIONS_BASE
                            },
                            {
                                label: 'Manage Servers',
                                icon: <ComputerIcon fontSize="small" />,
                                to: URL_TEACHER_SERVERS
                            }
                        ]}
                        edge="end"
                        color="inherit"
                    />
                    <AppBarMenu
                        iconText={currentUser.displayName || currentUser.email!}
                        textOverflow={true}
                        menuItems={[
                            {
                                label: 'Settings',
                                icon: <Settings fontSize="small" />,
                                to: URL_TEACHER_SETTINGS
                            },
                            {
                                label: 'Logout',
                                onClick: handleLogout,
                                icon: <Logout fontSize="small" />
                            }
                        ]}
                        edge="end"
                        color="inherit"
                    />
                </>
            );
        }
        return null;
    };

    const renderAdminMenus = () => {
        if (currentUser && user && user.permissions.admin) {
            return (
                <>
                    <AppBarMenu
                        iconText={`${Users.ADMIN.toString()}s`}
                        textOverflow={false}
                        menuItems={[
                            {
                                label: `Build Manager`,
                                icon: <AdminPanelSettings fontSize="small" />,
                                to: URL_ADMIN_BASE
                            },
                            {
                                label: 'User Manager',
                                icon: <ManageAccounts fontSize="small" />,
                                to: URL_ADMIN_MANAGE_USERS
                            }
                        ]}
                        edge="end"
                        color="inherit"
                    />
                </>
            )
        }
    }


    return (
        <AppBar component="nav">
            <Toolbar>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'white',
                        textDecoration: 'none',
                        cursor: 'pointer',
                    }}
                    href={URL_TEACHER_HOME}
                >
                    {AppUiObjectNames.APP_NAME.toUpperCase()}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                {currentUser ? (
                    <>
                        {renderAdminMenus()}
                        {renderTeacherMenus()}
                    </>
                ) : (
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleLogin}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '4px',
                        }}
                    >
                        <LoginRounded fontSize="small" sx={{ mr: 1 }} />
                        <Typography variant="subtitle1" sx={{ mr: 1 }}>Login</Typography>
                    </IconButton>
                )}
                <Tooltip title={isDarkMode ? "Toggle light mode" : "Toggle dark mode"}>
                    <IconButton onClick={handleToggleTheme} color="inherit">
                        {isDarkMode ? <Brightness4Icon /> : <Brightness7Icon />}
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>
    );
};

export default AgogeAppBar;
