import {ServerFormKeys} from "./ServerFormTypes";
import {Accordion, AccordionDetails, AccordionSummary, Box, useTheme} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {FormCheckBox, FormRadioBtns, StyledFormFieldLabel} from "../utils/FormFields";
import React from "react";
import {IUseServerForm} from "./useServerForm";

interface Props {
    serverForm: IUseServerForm,
    serverIndex: number;
    disableForm: boolean;
}

const ServerFormSettings: React.FC<Props> = ({serverForm, serverIndex, disableForm}) => {
    const theme = useTheme();
    return (
        <Accordion elevation={3}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                              sx={{background: theme.palette.action.hover}}>
                <StyledFormFieldLabel>Settings</StyledFormFieldLabel>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{paddingX: 1}}>
                    <FormCheckBox
                        formKey={ServerFormKeys.serverSettingHide}
                        disabled={disableForm}
                        fieldFn={(key) => serverForm.getField(key, serverIndex)}
                        onInputChange={(field, value) => {
                            serverForm.handleServerValueChange(field, value, serverIndex)
                        }}
                        label={"Hide Server"}
                        size={'small'}
                        helpText={'Hide server from server list on student pages. Server is still visible from the network view.'}
                    />

                    <FormCheckBox
                        formKey={ServerFormKeys.serverSettingCommunity}
                        fieldFn={(key) => serverForm.getField(key, serverIndex)}
                        disabled={disableForm}
                        onInputChange={(field, value) => {
                            serverForm.handleServerValueChange(field, value, serverIndex)
                        }}
                        size={'small'}
                        label={"Community Server"}
                        helpText={'Whether this server should be a shared server in a community build unit. Defaults to unshared.'}
                    />

                    <FormCheckBox
                        formKey={ServerFormKeys.serverSettingDeny}
                        fieldFn={(key) => serverForm.getField(key, serverIndex)}
                        disabled={disableForm}
                        onInputChange={(field, value) => {
                            serverForm.handleServerValueChange(field, value, serverIndex)
                        }}

                        size={'small'}
                        label={"Deny Outbound"}
                        helpText={'Allows incoming connections (i.e. SSH or RDP), but denies all traffic from within the server outward. Applies to all attached network interfaces.'}
                    />


                    <FormRadioBtns
                        formKey={ServerFormKeys.serverSettingRDP}
                        fieldFn={(key) => serverForm.getField(key, serverIndex)}
                        disabled={disableForm}
                        onInputChange={(field, value) => {
                            serverForm.handleServerValueChange(field, value, serverIndex)
                        }}
                        helpText={'The intended protocol for students to connect via. Windows defaults to RDP and Unix defaults to SSH.'}
                        options={[{value: 'rdp', label: 'Remote Desktop (RDP)'}, {value: 'ssh', label: 'SSH'}]}/>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default ServerFormSettings