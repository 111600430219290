export enum AppUiObjectNames {
    APP_NAME = 'Razornet Online',
    UNIT = 'Lab',
    WORKOUT = 'Workout',
}

export enum Users {
    TEACHER = 'Teacher',
    STUDENT = 'Student',
    ADMIN = 'Admin'
}
