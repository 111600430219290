import React, { useState, useEffect } from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Tabs,
    Tab
} from '@mui/material';
import { ImageSummaryLists } from '../../../services/Server/image.model'
import { serverService } from '../../../services/Server/server.service';
import { SelectChangeEvent } from '@mui/material/Select';

interface ImageTemplateSelectorProps {
    onTemplateSelect: (os: string, diskSize: number) => void;
}

const ImageTemplateSelector: React.FC<ImageTemplateSelectorProps> = ({ onTemplateSelect }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState({
        custom: '',
        debian: '',
        ubuntu: '',
        windows: ''
    });
    const [imageLists, setImageLists] = useState<ImageSummaryLists | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: ImageSummaryLists = await serverService.list();
                setImageLists(response);
            } catch (error) {
                console.error('Error fetching image lists:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleSelectChange = (family: keyof typeof selectedTemplate) => (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        const selectedImage = imageLists?.[family].find(image => image.self_link === selectedValue);
        setSelectedTemplate({
            ...selectedTemplate,
            [family]: selectedValue
        });
        if (selectedImage) {
            onTemplateSelect(
                selectedImage.os,
                Number(selectedImage?.disk_size ? selectedImage.disk_size : 10)
            );
        }
    };

    const renderSelectField = (family: keyof ImageSummaryLists) => (
        <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id={`${family}-select-label`}>
                Select image template*
            </InputLabel>
            <Select
                name={"image_template"}
                labelId={`${family}-select-label`}
                value={selectedTemplate[family]}
                onChange={handleSelectChange(family)}
                label="Select image template*"
                required
                aria-required
            >
                {imageLists && imageLists[family].map(image => (
                    <MenuItem
                        key={image.name}
                        value={image.self_link}
                        data-os={image.os}
                        data-disk-size={image.disk_size}
                    >
                        {image.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    return (
        <Box>
            <Tabs
                variant="fullWidth"
                value={selectedTab}
                onChange={handleTabChange}
                centered
                indicatorColor="primary"
                TabIndicatorProps={{
                    style: {
                        height: 3
                    }
                }}
                style={{ margin: "5px" }}
            >
                <Tab label="Custom" />
                <Tab label="Debian" />
                <Tab label="Ubuntu" />
                <Tab label="Windows" />
            </Tabs>
            <Box paddingTop={2}>
                {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={40} />
                ) : (
                    <>
                        {selectedTab === 0 && renderSelectField('custom')}
                        {selectedTab === 1 && renderSelectField('debian')}
                        {selectedTab === 2 && renderSelectField('ubuntu')}
                        {selectedTab === 3 && renderSelectField('windows')}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default ImageTemplateSelector;
