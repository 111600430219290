import React from "react";
import {Divider, Paper, Typography} from "@mui/material";
import FileStream from "./FileStream";
import FileStreamProps from "./fileProps"
import {useParams} from "react-router-dom";

const FileViewer: React.FC<FileStreamProps> = (
    {title, fileType}
) => {
    const { build_id } = useParams<{ build_id: string }>();
    return (
        <>
            <Paper
                elevation={0}
                variant={"outlined"}
                sx={{
                    width: "80%",
                    marginTop: 10,
                    padding: 5,
                    overflow: "auto"
                }}
            >
                <Typography variant={"h2"} mb={2}>
                    {title}
                </Typography>
                <Divider />
                <FileStream
                    buildId={build_id!}
                    fileType={fileType}
                />
            </Paper>
        </>
    )
}

export default FileViewer;