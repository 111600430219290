import React, {useEffect, useState} from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Cancel from '@mui/icons-material/Cancel';
import ComputerIcon from '@mui/icons-material/Computer';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import {useAuth} from '../../../context/AuthContext';
import {URL_TEACHER_SERVERS_CREATE} from '../../../router/urls';
import {imageService} from '../../../services/Server/image.service';
import {AgogeImage} from '../../../services/Server/image.model';
import {PubSub} from '../../../types/PubSub';

import ConfirmationDialog from '../../Common/Confirmation';
import DataTable from '../../Common/DataTable';
import StatusBadge from '../../Common/Status/StatusBadge';
import {StartButton, StopButton} from '../../Buttons/ControlButtons';
import {ServerConnectButton} from "../../Buttons/ConnectButton/ServerConnectButton";

const ImageListTable: React.FC = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [activeImages, setImages] = useState<AgogeImage[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [imageName, setImageName] = useState<string>('');
    const [snackbar, setSnackbar] = useState<{ open: boolean; severity: 'success' | 'error' | 'warning' | 'info'; message: string }>({ open: false, severity: 'success', message: '' });
    const [buttonStates, setButtonStates] = useState({
        hasCheckedIn: false,
        hasCheckedOut: false,
        somethingSelected: false,
        isAdmin: false
    });
    const [checkInLoading, setCheckInLoading] = useState<boolean>(false);
    const [checkOutLoading, setCheckOutLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deletingRows, setDeletingRows] = useState<string[]>([]);

    const fetchImages = async (showLoading: boolean = false) => {
        if (showLoading) {
            setLoading(true);
        }
        try {
            const fetchedImages = await imageService.list();
            setImages(prevImages => {
                if (JSON.stringify(prevImages) !== JSON.stringify(fetchedImages)) {
                    return fetchedImages;
                }
                return prevImages;
            });

            setDeletingRows(prevDeletingRows =>
                prevDeletingRows.filter(deletingRowName =>
                    fetchedImages.some(image => image.name === deletingRowName)
                )
            );
        } catch (error) {
            console.error(error);
            setError("Failed to fetch images");
        } finally {
            if (showLoading) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchImages(true);
        const intervalId = setInterval(fetchImages, 5000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const hasCheckedIn = selectedRows.some((row: any) => row.state === 4 || row.state === 6);
        const hasCheckedOut = selectedRows.some((row: any) => row.state === 0);
        const somethingSelected = selectedRows.length > 0;
        const isAdmin = !!(user && user.permissions && user.permissions.admin);

        setButtonStates({
            hasCheckedIn,
            hasCheckedOut,
            somethingSelected,
            isAdmin,
        });
    }, [selectedRows, user]);

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    const handlePostRequest = async (action: string) => {
        try {
            await imageService.post_action(selectedRows, action);
        } catch (error) {
            console.error('Error', error);
        }
    };

    const buttonController = async (action: string, severity: 'success' | 'error' | 'warning' | 'info', message: string) => {
        switch (action) {
            case 'checkOut':
                setCheckOutLoading(true);
                await handlePostRequest(String(PubSub.Actions.IMAGE_CHECK_OUT));
                setCheckOutLoading(false);
                setSnackbar({ open: true, severity, message });
                break;
            case 'checkIn':
                setCheckInLoading(true);
                await handlePostRequest(String(PubSub.Actions.IMAGE_CHECK_IN));
                setCheckInLoading(false);
                setSnackbar({ open: true, severity, message });
                break;
            case 'delete':
                if (selectedRows.length !== 1) {
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'Please select only one row for deletion.',
                    });
                    return;
                }
                setDeleteLoading(true);
                setImageName(selectedRows[0]?.name || '');
                setOpenDialog(true);
                return;
            case 'cancel':
                setCheckInLoading(true);
                await handlePostRequest(String(PubSub.Actions.IMAGE_CANCEL_CHANGES));
                setCheckInLoading(false);
                setSnackbar({ open: true, severity, message });
                return;
            default:
                console.error(message);
                setSnackbar({ open: true, severity: 'error', message: 'An error occurred' });
                break;
        }
        fetchImages(false);
    };

    const handleConfirmDelete = async () => {
        const rowName = selectedRows[0].name;
        setDeletingRows((prevDeletingRows) => [...prevDeletingRows, rowName]);
        setOpenDialog(false);

        try {
            await imageService.delete_image(rowName);
            setSnackbar({ open: true, severity: 'warning', message: 'Server deletion task started ...' });
        } catch (error) {
            console.error('Error deleting server:', error);
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Failed to delete server.',
            });
        } finally {
            setDeletingRows((prevDeletingRows) => prevDeletingRows.filter(name => name !== rowName));
            setImageName('');
            setDeleteLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDeleteLoading(false);
        setImageName('');
    };

    // Define columns directly without memoization
    const newColumns = [
        {
            field: 'state',
            headerName: 'State',
            align: 'center',
            minWidth: 120,
            renderCell: (params: any) => {
                const isLoading = ![0, 4, 6, 11].includes(params.value);
                let statusText = '';
                let statusColor = '';

                if (params.value === 0) {
                    statusText = "Not Built";
                    statusColor = '#b0b0b0';
                } else if (params.value === 4) {
                    statusText = "Running";
                    statusColor = '#388e3c';
                } else if (params.value === 6) {
                    statusText = "Stopped";
                    statusColor = '#42a5f5';
                } else if (params.value === 11) {
                    statusText = "Broken";
                    statusColor = '#d32f2f';
                } else {
                    statusText = "Working";
                    statusColor = '#ada500';
                }

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                        {isLoading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <StatusBadge color={statusColor} label={statusText} sx={{ color: "black" }} />
                        )}
                    </div>
                );
            }
        },
        { field: 'name', headerName: 'Name', width: 150 },
        {
            field: 'status',
            headerName: 'Availability',
            minWidth: 120,
            renderCell: (
                params: any) => {
                let statusText = '';
                let statusColor = '';
                if (params.value === 0) {
                    statusText = "Checked In";
                    statusColor = '#388e3c';
                } else if (params.value === 1) {
                    statusText = "Checked Out";
                    statusColor = '#d32f2f';
                }
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                        <StatusBadge color={statusColor} label={statusText} />
                    </div>
                );
            }
        },
        {
            field: 'in_use_by',
            headerName: 'In use by',
            width: 200,
        },
        {
            field: 'connect',
            headerName: 'Connect',
            width: 150,
            renderCell: (params: any) => (
                <ServerConnectButton item={params.row} />
            )
        }
    ];
    // Define table title directly without memoization
    const tableTitle = (
        <Stack
            alignItems={"center"}
            direction={"row"}
            gap={1}
        >
            <ComputerIcon fontSize={"large"} />
            <Typography
                variant={"h5"}
                component={"h2"}
            >
                Server Manager
            </Typography>
        </Stack>
    );

    return (
        <>
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: 2
                }}
            >
                <Box sx={{ display: 'flex', gap: 2 }} >
                    <Button
                        sx={{ m: 2 }}
                        variant="contained"
                        color="primary"
                        href={URL_TEACHER_SERVERS_CREATE}
                    >
                        Create Image
                    </Button>
                    <Tooltip title="Save changes to server image and release reservation." arrow>
                    <span>
                        <Button
                            sx={{ m: 2 }}
                            variant="outlined"
                            disabled={!buttonStates.hasCheckedIn}
                            color="primary"
                            startIcon={!checkInLoading && <LockOpenOutlinedIcon fontSize="large" />}
                            onClick={() => buttonController('checkIn', 'success', 'Checking in server ...')}
                        >
                            {checkInLoading ? <CircularProgress size={24} /> : 'Check in'}
                        </Button>
                    </span>
                    </Tooltip>
                    <Tooltip title="Reserve server image for updates" arrow>
                    <span>
                        <Button
                            sx={{ m: 2 }}
                            variant="outlined"
                            disabled={!buttonStates.hasCheckedOut}
                            color="primary"
                            startIcon={!checkOutLoading && <LockOutlinedIcon fontSize="large" />}
                            onClick={() => buttonController('checkOut', 'success', 'Checking out server ...')}
                        >
                            {checkOutLoading ? <CircularProgress size={24} /> : 'Check out'}
                        </Button>
                    </span>
                    </Tooltip>
                    <StartButton
                        sx={{ m: 2 }}
                        text={"start"}
                        use={"server"}
                        data={selectedRows}
                        isDisabled={(selectedRows.some((row: any) => row.status === 0)) || !(selectedRows.some((row: any) => row.state === 6))}
                    />
                    <StopButton
                        sx={{ m: 2 }}
                        text={"stop"}
                        use={"server"}
                        data={selectedRows}
                        isDisabled={(selectedRows.some((row: any) => row.status === 0)) || !(selectedRows.some((row: any) => row.state === 4))}
                    />
                    <Tooltip title="Cancel changes to image and release reservation" arrow>
                    <span>
                        <Button
                            sx={{ m: 2 }}
                            variant="outlined"
                            disabled={(selectedRows.some((row: any) => row.status === 0)) || !buttonStates.somethingSelected }
                            color="error"
                            startIcon={!checkInLoading && <Cancel fontSize="large" />}
                            onClick={() => buttonController('cancel', 'success', 'Canceling Changes ...')}
                        >
                            {checkInLoading ? <CircularProgress size={24} /> : 'Cancel'}
                        </Button>
                    </span>
                    </Tooltip>
                    {buttonStates.isAdmin && (
                        <Button
                            sx={{ m: 2 }}
                            variant="outlined"
                            color="error"
                            startIcon={!deleteLoading && <DeleteForeverOutlinedIcon fontSize="large" />}
                            disabled={selectedRows.length !== 1 || deleteLoading}
                            onClick={() => buttonController('delete', 'warning', 'Deleting the server...')}
                        >
                            {deleteLoading ? <CircularProgress size={24} /> : 'Delete'}
                        </Button>
                    )}
                </Box>
            </Container>
            <Paper
                elevation={1}
                square={false}
                sx={{
                    padding: "10px"
                }}
            >
                <DataTable
                    title={tableTitle}
                    columns={newColumns}
                    data={activeImages}
                    onCheckboxChange={setSelectedRows}
                    showSearch={true}
                    getRowId={(row) => row.name}
                    loading={loading}
                />
            </Paper>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    <AlertTitle>{snackbar.severity.charAt(0).toUpperCase() + snackbar.severity.slice(1)}</AlertTitle>
                    {snackbar.message}
                </Alert>
            </Snackbar>

            <ConfirmationDialog
                open={openDialog}
                handleClose={handleCloseDialog}
                handleConfirm={handleConfirmDelete}
                expectedText={imageName}
            />
        </>
    );
};

export default ImageListTable;