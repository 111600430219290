import {useNavigate, useParams} from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    Assessment,
    LMSQuiz,
    Network,
    SpecificationEdit,
    Summary,
    TeachingConcept
} from "../../../../services/Specification/specificationEdit.model";
import {useForm} from "../../../../hooks/useForm";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Paper,
    Stack,
    Step,
    StepButton,
    Stepper, Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import SummaryForm from "./SummaryForm";
import {LoadingButton} from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import NetworkForm from "./NetworkForm";
import {useForms} from "../../../../hooks/useForms";
import {EditorFormFactory} from "./utils/EditorFormFactory";
import ServerForm from "./ServerForm/ServerForm";
import {AgogeImage} from "../../../../services/Server/image.model";
import {imageService} from "../../../../services/Server/image.service";
import {EditorForms, NetworkFormKeys, SummaryFormKeys, WebApplicationFormKeys} from "./utils/EditorTypes";

import {ServerFormKeys} from "./ServerForm/ServerFormTypes";
import {FormType} from "../../../../types/Form";
import Review from "./Review/Review";
import {specificationEditService} from "../../../../services/Specification/specificationEdit.service";
import WebApplicationForm from "./WebApplicationForm";
import {WebApplication} from "../../../../services/Specification/specification.model";
import AssessmentForm from "./AssessmentForms/AssessmentForm";
import {useAssessmentForm} from "./AssessmentForms/useAssessmentForm";
import {useServerForm} from "./ServerForm/useServerForm";
import {useModal} from "mui-modal-provider";
import SimpleSnackbar from "../../../Common/SnackBar/SnackBar";
import StartupScriptDialog from "./AssessmentForms/StartupScriptDialog";
import {AssessmentFormKeys} from "./AssessmentForms/AssessmentFormTypes";
import {ErrorOutlined} from "@mui/icons-material";
import {URL_TEACHER_SPECIFICATIONS_BASE} from "../../../../router/urls";

const Editor: React.FC = () => {
    const { showModal } = useModal();
    const navigate = useNavigate();
    const initialStep = 0;
    const { edit_id } = useParams<{ edit_id: string }>();
    const [initialized, setInitialized] = useState(false);
    const [startupScripts, setStartupScripts] = useState<{
        pending: boolean;
        data: string[];
    }>({ pending: true, data: [] });
    const [images, setImages] = useState<{ pending: boolean; data: AgogeImage[] }>({
        pending: true,
        data: [],
    });
    const [specification, setSpecification] = useState<{
        pending: boolean;
        data: SpecificationEdit | null;
    }>({ pending: true, data: null });
    const [activeStep, setActiveStep] = useState(initialStep);
    const [submitting, setSubmitting] = useState(false);
    const summaryForm = useForm({
        initialize: false,
    });
    const networkForms = useForms({
        initialize: false,
    });
    const webApplicationForms = useForms({
        initialize: false,
    });
    const serverForm = useServerForm({ initialize: false });
    const assessmentForm = useAssessmentForm({ initialize: false });
    const [erroredStep, setErroredStep] = useState<EditorForms | null>(null);

    const onUploadStartScript = () => {
        showModal(StartupScriptDialog, {
            onClose: (newFile) => {
                if (newFile) {
                    assessmentForm.updateAssessmentField(
                        AssessmentFormKeys.assessmentScript,
                        newFile
                    );
                    setStartupScripts((prevState) => {
                        const fileExists = prevState.data.some(
                            (file) => file === newFile
                        );
                        if (!fileExists) {
                            return {
                                ...prevState,
                                data: [...prevState.data, newFile],
                            };
                        }
                        return prevState;
                    });
                    showModal(SimpleSnackbar, {
                        message: "Successfully added Start Script!",
                        severity: "success",
                    });
                }
            },
        });
    };

    useEffect(() => {
        if (edit_id) {
            loadSpecification();
        }
    }, [edit_id]);

    useEffect(() => {
        loadImages();
        loadStartupScripts();
        const hash = window.location.hash;
        if (hash) {
            const step = parseInt(hash.substring(1), 10);
            if (!isNaN(step)) {
                setActiveStep(step);
            }
        }
    }, []);

    useEffect(() => {
        // There may be a better way of doing this, but I'm not entirely sure. This listens to the
        // network forms and server forms, specifically
        //
        // network.name
        // server.nics.network & server.nics.network_ip
        //
        // Whenever network changes, we revalidate the server nics to make sure we are still valid. When
        // server nics changes, we'll revalidate to make sure we align with networks.
        //

        const copyOfNetworkForms = [...(networkForms.forms || [])];
        const networks = (copyOfNetworkForms || []).reduce(
            (acc: { [key: string]: string }, form: FormType) => {
                const networkField = form[NetworkFormKeys.networkName];
                const subnetField = form[NetworkFormKeys.networkSubnets];

                if (
                    networkField &&
                    networkField.error == null &&
                    subnetField &&
                    subnetField.error == null
                ) {
                    acc[networkField.value] = subnetField.value;
                }
                return acc;
            },
            {}
        );

        serverForm.validateNetworkAndServerNics(networks);
    }, [
        networkForms.forms?.map(
            (field) => field[NetworkFormKeys.networkName].value
        ),
        serverForm.forms.map((field) =>
            field[ServerFormKeys.serverNetworks].map(
                (f) =>
                    f[ServerFormKeys.serverNicNetwork].value +
                    f[ServerFormKeys.serverNicIPv4Addr].value
            )
        ),
    ]);

    const loadImages = () => {
        imageService
            .list()
            .then((res) => setImages({ pending: false, data: res }))
            .catch((err) => setImages({ ...images, pending: false }));
    };

    const loadStartupScripts = () => {
        specificationEditService
            .getStartupScripts()
            .then((res) => setStartupScripts({ pending: false, data: res }))
            .catch((err) => setStartupScripts({ ...startupScripts, pending: false }));
    };

    const loadSpecification = () => {
        specificationEditService
            .get(edit_id!)
            .then((res: SpecificationEdit) => {
                setSpecification({ pending: false, data: res });
                initializeForms(res);
            })
            .catch((err: any) => setSpecification({ ...specification, pending: false }));
    };

    const initializeForms = (specification: SpecificationEdit) => {
        const localNetworkForms = [];
        for (let networkService of specification.networks || []) {
            localNetworkForms.push(EditorFormFactory.generateNetworkForm(networkService));
        }

        const localWebAppForms = [];
        for (let webApp of specification?.web_applications || []) {
            localWebAppForms.push(EditorFormFactory.generateWebAppForm(webApp));
        }


        assessmentForm.handleInitialization(specification.assessment, specification.lms_quiz);
        serverForm.handleInitialization(specification.servers);

        summaryForm.handleInitialization(
            EditorFormFactory.generateSummaryForm(specification?.summary)
        );
        networkForms.handleInitialization(localNetworkForms);
        webApplicationForms.handleInitialization(localWebAppForms);

        setInitialized(true);
    };

    const handleNavigation = async (newStep: number) => {
        const currentForm = Object.values(EditorForms)[activeStep];
        const nextForm = Object.values(EditorForms)[newStep];

        if (currentForm === EditorForms.Review) {
            // Directly navigate without submission if moving away from Review form
            setActiveStep(newStep);
            window.location.hash = `#${newStep}`;
            return;
        }

        if (
            currentForm === EditorForms.WebApplications &&
            (webApplicationForms.forms === null || webApplicationForms.forms.length === 0)
        ) {
            setActiveStep(newStep);
            window.location.hash = `#${newStep}`;
            return;
        }

        if (
            currentForm === EditorForms.Assessment &&
            (assessmentForm.isEmpty())
        ) {
            setActiveStep(newStep);
            window.location.hash = `#${newStep}`;
            return;
        }

        if (
            currentForm === EditorForms.Servers &&
            (serverForm.isEmpty())
        ) {
            setActiveStep(newStep);
            window.location.hash = `#${newStep}`;
            return
        }

        if (
            nextForm === EditorForms.Servers &&
            (networkForms.forms === null || networkForms.forms.length === 0)
        ) {
            showModal(SimpleSnackbar, {
                message: "You must add a network before adding servers.",
                severity: "error",
            });
            return;
        }

        if (!doesCurrentStepHaveErrors(currentForm)) {
            await handleOnSubmit().then(resp => {
                if (newStep !== initialStep) {
                    window.location.hash = `#${newStep}`;
                }
                setActiveStep(newStep);
            }).catch((error) => {
                console.log(error);
                showModal(SimpleSnackbar, {
                    message: "Validation failed. Please correct any errors before proceeding.",
                    severity: "error",
                });
            });
        } else {
            showModal(SimpleSnackbar, {
                message: "Please correct any errors before proceeding.",
                severity: "error",
            });
        }
    };

    const handleNext = () => {
        handleNavigation(activeStep + 1);
    };

    const handleBack = () => {
        handleNavigation(activeStep - 1);
    };

    const handleNavigateTo = (form: EditorForms) => {
        const stepIndex = Object.values(EditorForms).indexOf(form);
        handleNavigation(stepIndex);
    };

    const handleOnSubmit = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            setSubmitting(true);

            const formToKeyMap: { [key in EditorForms]: string } = {
                [EditorForms.Summary]: "summary",
                [EditorForms.Networks]: "networks",
                [EditorForms.Servers]: "servers",
                [EditorForms.WebApplications]: "web_applications",
                [EditorForms.Assessment]: assessmentForm?.isLMS
                    ? "lms_quiz"
                    : "assessment",
                [EditorForms.Review]: "review",
            };

            const key: string = formToKeyMap[Object.values(EditorForms)[activeStep]];
            const body = generateSpecification();
            const dataToPost =
                key !== "review" ? body![key as keyof SpecificationEdit] : body;
            let postBody = {
                form_type: key === "lms_quiz" ? "assessment" : key,
            };
            if (Array.isArray(dataToPost)) {
                // @ts-ignore
                postBody[key as keyof typeof postBody] = dataToPost;
            } else {
                postBody = {
                    ...(dataToPost as object),
                    ...postBody,
                };
            }
            console.log(specification.data?.id);

            specificationEditService
                .post(postBody, edit_id!)
                .then((res) => {
                    setSubmitting(false);
                    setSpecification({ ...specification, data: res });

                    if (key === EditorForms.Review.toLowerCase()) {
                        navigate(URL_TEACHER_SPECIFICATIONS_BASE);
                    }

                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    setSubmitting(false);
                    showModal(SimpleSnackbar, {
                        message: "Failed to update Specification!",
                        severity: "error",
                    });
                    reject();
                });
        });
    };

    const generateSpecification = (): SpecificationEdit | undefined => {
        // this converts the forms to a specification object

        if (!specification.pending) {
            const summaryValues: Summary = {
                ...specification.data!.summary,
                name: summaryForm.form![SummaryFormKeys.summaryName].value,
                description: summaryForm.form![SummaryFormKeys.summaryDescription].value,
                teacher_instructions_url: summaryForm.form![SummaryFormKeys.summaryTeacherInstructions].value,
                student_instructions_url: summaryForm.form![SummaryFormKeys.summaryStudentInstructions].value,
                unit_type: summaryForm.form![SummaryFormKeys.summaryUnitType].value,
                author: summaryForm.form![SummaryFormKeys.summaryAuthor].value,
                tags: ((summaryForm.form![SummaryFormKeys.summaryTeachingConcepts].value as TeachingConcept[])
                    .map((t) => t.id) as unknown as TeachingConcept[]),
            }

            const networkValues: Network[] = [];
            for (const form of networkForms.forms!) {
                networkValues.push({
                    name: form[NetworkFormKeys.networkName].value,
                    subnets: [
                        {
                            name: "default",
                            ip_subnet: form[NetworkFormKeys.networkSubnets].value,
                            promiscuous_mode: form[NetworkFormKeys.networkPromiscuous].value,
                        },
                    ],
                });
            }

            const webAppValues: WebApplication[] = [];
            for (const form of webApplicationForms.forms || []) {
                webAppValues.push({
                    name: form[WebApplicationFormKeys.webAppName].value,
                    host_name: form[WebApplicationFormKeys.webAppHostName].value,
                    starting_directory: form[WebApplicationFormKeys.webAppStartingDirectory].value
                });
            }

            const assessment = assessmentForm.render();
            return {
                ...specification.data!,
                id: specification.data!.id,
                unit_type: summaryValues.unit_type,
                build_type: specification.data!.build_type,
                edit_id: specification.data!.edit_id,
                status: "",
                discriminator: "",
                lms_quiz: assessmentForm.isLMS ? (assessment as LMSQuiz) : undefined,
                assessment: assessmentForm.isLMS ? undefined : (assessment as Assessment),
                web_applications: webAppValues,
                summary: summaryValues,
                networks: networkValues,
                servers: serverForm.render(images.data),
            };
        }
    };

    const doesCurrentStepHaveErrors = (
        step: EditorForms,
        reviewAll = false
    ): boolean => {
        const validateEntireForm = () => {
            for (const form of Object.values(EditorForms)) {
                if (form !== EditorForms.Review && doesCurrentStepHaveErrors(form, true)) {
                    if (erroredStep !== form) {
                        setErroredStep(form);
                    }
                    return true;
                }
            }
            return false;
        };

        if (!specification.pending) {
            let hasError = false;
            switch (step) {
                case EditorForms.Summary:
                    hasError = summaryForm.hasErrors();
                    break;
                case EditorForms.Networks:
                    hasError = networkForms.hasErrors();
                    break;
                case EditorForms.WebApplications:
                    hasError = webApplicationForms.hasErrors();
                    break;
                case EditorForms.Servers:
                    hasError = serverForm.hasErrors();
                    break;
                case EditorForms.Assessment:
                    hasError = assessmentForm.hasErrors();
                    break;
                case EditorForms.Review:
                    hasError = validateEntireForm();
                    break;
                default:
                    break;
            }

            return hasError;
        }

        return false;
    };

    const theme = useTheme();
    const steps = Object.values(EditorForms);

    const hasError = doesCurrentStepHaveErrors(steps[activeStep]);
    return (
        <Container sx={{ width: "80%", overflow: "hidden", mt: theme.spacing(9) }}>
            <Paper elevation={1} square={false} sx={{ width: "100%", paddingY: theme.spacing(1) }}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepButton color="inherit" onClick={() => handleNavigateTo(step as EditorForms)}>
                                {step}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                <Stack sx={{ padding: theme.spacing(2), overflowY: "auto" }}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Stack gap={1} direction={"row"} alignItems={"center"}>
                            <Typography variant={"h6"}>{steps[activeStep]}</Typography>

                            {hasError && (
                                <Tooltip
                                    title={
                                        steps[activeStep] === EditorForms.Review
                                            ? "Form " + erroredStep + " is invalid"
                                            : "Form " + steps[activeStep] + " is invalid"
                                    }
                                >
                                    <ErrorOutlined color={"error"} />
                                </Tooltip>
                            )}

                            {specification.pending && (
                                <CircularProgress thickness={5} size={20} color="primary" />
                            )}
                        </Stack>
                        {activeStep === 5 && (
                            <LoadingButton
                                size="small"
                                color="primary"
                                onClick={handleOnSubmit}
                                loading={submitting}
                                loadingPosition="end"
                                endIcon={<SaveIcon />}
                                variant="contained"
                                disabled={specification.pending || hasError}
                            >
                                Publish
                            </LoadingButton>
                        )}
                    </Stack>

                    <Box>
                        {
                            Object.values(EditorForms).map((form, index) => {
                                if (form === EditorForms.Summary && index === activeStep)
                                    return (
                                        <SummaryForm
                                            disableForm={specification.pending || submitting}
                                            formHook={summaryForm}
                                            specification={specification}
                                        />
                                    );
                                else if (form === EditorForms.Networks && index === activeStep)
                                    return (
                                        <NetworkForm
                                            disableForm={specification.pending || submitting}
                                            formHooks={networkForms}
                                            specification={specification}
                                            serverForms={serverForm}
                                        />
                                    );
                                else if (form === EditorForms.Servers && index === activeStep)
                                    return (
                                        <ServerForm
                                            disableForm={specification.pending || submitting}
                                            serverForm={serverForm}
                                            specification={specification}
                                            images={images}
                                            remoteNetworkInterfaces={specification.data?.networks || []}
                                            localNetworkInterfaces={(networkForms.forms || []).map((f => f[NetworkFormKeys.networkName].value))}
                                        />
                                    );
                                else if (form === EditorForms.WebApplications && index === activeStep)
                                    return (
                                        <WebApplicationForm
                                            disableForm={specification.pending || submitting}
                                            formHooks={webApplicationForms}
                                            specification={specification}
                                        />
                                    );
                                else if (form === EditorForms.Assessment && index === activeStep)
                                    return (
                                        <AssessmentForm
                                            disableForm={specification.pending || submitting}
                                            assessmentFormHook={assessmentForm}
                                            specification={specification}
                                            startupScripts={startupScripts}
                                            onUploadStartScript={onUploadStartScript}
                                            remoteServers={(specification.data?.servers || []).map((server => server.name))}
                                            localServers={serverForm.forms.map((f => f[ServerFormKeys.serverName].value))}
                                        />
                                    );
                                else if (form === EditorForms.Review && index === activeStep)
                                    return (
                                        <Review specification={generateSpecification()}
                                                onNavigateToStep={handleNavigateTo}/>
                                    );
                            })

                        }
                    </Box>
                </Stack>

                {activeStep !== steps.length - 1 && (
                    <Stack direction={"row"} sx={{ pt: 2 }}>
                        <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                            Back {activeStep !== 0 && "(" + steps[activeStep - 1] + ")"}
                        </Button>

                        <Button onClick={handleNext} sx={{ ml: "auto", mr: 1 }}>
                            Next ({steps[activeStep + 1]})
                        </Button>
                    </Stack>
                )}
            </Paper>
        </Container>
    );
};

export default Editor;
