import {CommonEditorChildrenProps, SummaryFormKeys} from "./utils/EditorTypes";
import React, {useState} from "react";
import {Box, Chip, ListItem, Stack, Typography, useTheme} from "@mui/material";
import {
    FormAutocompleteField,
    FormFields,
    FormGroup, FormGroupOutline, FormMultiSelect,
    FormTextField,
    StyledFormFieldLabel,
    StyledFormGroupLabel
} from "./utils/FormFields";
import {mapTeachingConcepts, TeachingConcepts, UnitType} from "../../../../types/BuildConstants";
import {TeachingConcept} from "../../../../services/Specification/specificationEdit.model";
import {IUseFormHook} from "../../../../hooks/useForm";
import ChipListView from "../../../Common/ChipListView";

interface Props extends CommonEditorChildrenProps {
    formHook: IUseFormHook
}


const SummaryForm: React.FC<Props> = (props: Props) => {
    const theme = useTheme();
    const [conceptLookup, setLookup] = useState(mapTeachingConcepts(Object.values(TeachingConcepts)))

    const handleRemoveTeachingConcept = (concept: TeachingConcept): void => {
        const formField = props.formHook.form![SummaryFormKeys.summaryTeachingConcepts];
        const updated = formField.value.filter((item: TeachingConcept | string) => {
            if (typeof item === 'string') {
                // Compare directly with the concept name if the item is a string
                return item.toLowerCase() !== concept.name.toLowerCase();
            } else {
                // Compare the name property if the item is a TeachingConcept object
                return item.name.toLowerCase() !== concept.name.toLowerCase();
            }
        });

        props.formHook.handleInputChange(SummaryFormKeys.summaryTeachingConcepts, updated);
    }

    const handleUpdateTeachingConcept = (concepts: (TeachingConcept | string)[]): void => {
        if (concepts.length > 0 && typeof concepts[concepts.length - 1] == 'string') {
            const value: string = concepts[concepts.length - 1] as string;
            const id = value.split(' ').join('_').toLowerCase()
            const newConcept = {
                id: id,
                name: value
            };

            concepts.splice(concepts.length - 1, 1, newConcept);
        }

        props.formHook.handleInputChange(SummaryFormKeys.summaryTeachingConcepts, concepts);
    }


    const teachingConceptExists = (concept: string): boolean => {
        return props.formHook.getFormField(SummaryFormKeys.summaryTeachingConcepts)?.value
            .map((tc: TeachingConcept) => tc.name).includes(concept)
    }

    return (
        <FormFields>
            <FormGroupOutline showOverlay={props.disableForm}>
                <FormGroup>
                    <StyledFormFieldLabel>Name</StyledFormFieldLabel>
                    <FormTextField
                        formKey={SummaryFormKeys.summaryName}
                        fieldFn={(key) => props.formHook.getFormField(key)}
                        disabled={props.disableForm}
                        onInputChange={(field, value) => {
                            props.formHook.handleInputChange(field, value)
                        }}
                    />
                </FormGroup>
            </FormGroupOutline>

            <FormGroupOutline showOverlay={props.disableForm}>
                <FormGroup>
                    <StyledFormFieldLabel>Author</StyledFormFieldLabel>
                    <FormTextField
                        formKey={SummaryFormKeys.summaryAuthor}
                        fieldFn={(key) => props.formHook.getFormField(key)}
                        disabled={props.disableForm}
                        onInputChange={(field, value) => {
                            props.formHook.handleInputChange(field, value)
                        }}
                    />
                </FormGroup>
            </FormGroupOutline>


            <FormGroupOutline showOverlay={props.disableForm}>

                <FormGroup>
                    <StyledFormFieldLabel>Unit Type</StyledFormFieldLabel>
                    <Stack>
                        <Typography variant={'subtitle2'}>Defines the type of unit setup.</Typography>
                        <ul style={{margin: theme.spacing(.5)}}>
                            <li>
                                <Typography variant={'subtitle2'}>
                                    'Solo' means each student gets their own isolated workout lab, independent of
                                    others.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant={'subtitle2'}>
                                    'Community' means all workouts are built within a shared network environment, where
                                    resources might be shared among students.
                                </Typography>
                            </li>
                        </ul>
                    </Stack>

                    <FormAutocompleteField
                        options={Object.values(UnitType)}
                        formKey={SummaryFormKeys.summaryUnitType}
                        disabled={props.disableForm}
                        fieldFn={(key) => props.formHook.getFormField(key)}
                        onInputChange={(field, value) => {
                            props.formHook.handleInputChange(field, value)
                        }}
                    />
                </FormGroup>
            </FormGroupOutline>


            <FormGroupOutline showOverlay={props.disableForm}>

                <Stack gap={2}>
                    <FormGroup>
                        <StyledFormFieldLabel>Teacher Instructions URL</StyledFormFieldLabel>
                        <FormTextField
                            placeholder={'some-example.com'}
                            formKey={SummaryFormKeys.summaryTeacherInstructions}
                            fieldFn={(key) => props.formHook.getFormField(key)}
                            onInputChange={(field, value) => {
                                props.formHook.handleInputChange(field, value)
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <StyledFormFieldLabel>Student Instructions URL</StyledFormFieldLabel>
                        <FormTextField
                            placeholder={'some-example.com'}
                            formKey={SummaryFormKeys.summaryStudentInstructions}
                            fieldFn={(key) => props.formHook.getFormField(key)}
                            onInputChange={(field, value) => {
                                props.formHook.handleInputChange(field, value)
                            }}
                        />
                    </FormGroup>
                </Stack>
            </FormGroupOutline>

            <FormGroupOutline showOverlay={props.disableForm}>

                <FormGroup>
                    <StyledFormFieldLabel>Description</StyledFormFieldLabel>
                    <FormTextField
                        placeholder={'Brief lab description up to 200 characters'}
                        formKey={SummaryFormKeys.summaryDescription}
                        disabled={props.disableForm}
                        fieldFn={(key) => props.formHook.getFormField(key)}
                        onInputChange={(field, value) => {
                            props.formHook.handleInputChange(field, value)
                        }}
                    />
                </FormGroup>
            </FormGroupOutline>


            <FormGroupOutline showOverlay={props.disableForm}>

                <FormGroup>
                    <StyledFormFieldLabel>Teaching Concepts</StyledFormFieldLabel>

                    <Stack gap={1}>
                        <Typography variant={"subtitle2"}>Optional, at a glace lab descriptors</Typography>
                        <ChipListView
                            values={props.formHook.getFormField(SummaryFormKeys.summaryTeachingConcepts)?.value || []}
                            renderLabel={(r: TeachingConcept) => r.name}
                            onRemove={(value) => handleRemoveTeachingConcept(value)}
                        />
                    </Stack>
                    <FormMultiSelect
                        renderValueOverrideText={"Select Concepts"}
                        options={Object.values(TeachingConcepts)}
                        disabled={props.disableForm}
                        isSelected={(value) => teachingConceptExists(value)}
                        onSelection={(value: (TeachingConcept | string)[]) => {
                            handleUpdateTeachingConcept(value);
                        }}
                        onRemove={(values: string[], value: string) => {
                            handleRemoveTeachingConcept({id: value, name: value})
                        }}
                        placeholder={'Optional, at a glance lab descriptors'}
                        formKey={SummaryFormKeys.summaryTeachingConcepts}
                        fieldFn={(key) => props.formHook.getFormField(key)}
                        onInputChange={(field, value) => {
                            props.formHook.handleInputChange(field, value)
                        }}
                    />
                </FormGroup>
            </FormGroupOutline>

        </FormFields>
    )
}

export default SummaryForm;