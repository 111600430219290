import {CommonEditorChildrenProps, NetworkFormKeys, SummaryFormKeys} from "../utils/EditorTypes";
import React, {useEffect, useState} from "react";

import {IServerForm, ServerFormKeys} from "./ServerFormTypes";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Button, Checkbox,
    Chip, Collapse, FormControlLabel,
    IconButton,
    ListItem,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import {
    FormAutocompleteField, FormSwitch,
    FormFields,
    FormGroup, FormGroupOutline, FormMultiSelect,
    FormTextField,
    StyledFormFieldLabel,
    StyledFormGroupLabel, FormCheckBox, FormRadioBtns, FormMuliTextField
} from "../utils/FormFields";
import {TransitionGroup} from 'react-transition-group';

import {UseFormsHook} from "../../../../../hooks/useForms";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {Add, Comment, Delete, ErrorOutline} from "@mui/icons-material";
import {EditorFormFactory} from "../utils/EditorFormFactory";
import {AgogeImage} from "../../../../../services/Server/image.model";
import {Network} from "../../../../../services/Specification/specification.model";
import {FormType} from "../../../../../types/Form";
import {EmptyListItem, EmptyListItemText} from "../Review/ReviewCommonStyles";
import ServerFormSettings from "./ServerFormSettings";
import ServerFormNic from "./ServerFormNic";
import ServerNetworks from "./ServerNetworks";
import {IUseServerForm} from "./useServerForm";
import Alert from "@mui/material/Alert";

interface Props extends CommonEditorChildrenProps {
    serverForm: IUseServerForm;
    images: { pending: boolean, data: AgogeImage[] };
    remoteNetworkInterfaces: Network[];
    localNetworkInterfaces: string[];
}

const ServerForm: React.FC<Props> = ({
    serverForm,
    images,
    remoteNetworkInterfaces,
    localNetworkInterfaces,
    disableForm,
    specification
}: Props) => {
    const theme = useTheme();

    // Default toggle for the first network service
    const [accordionStates, setAccordionStates] = useState<number[]>([0]);

    const toggleAccordion = (index: number) => {
        if (accordionStates.includes(index)) {
            setAccordionStates(accordionStates.filter(idx => idx !== index));
        } else {
            setAccordionStates([...accordionStates, index]);
        }
    }

    useEffect(() => {
        // revalidate form
    }, []);


    const removeServer = (index: number) => {
        serverForm.removeServer(index);

        const indexOfIndex = accordionStates.findIndex(idx => idx === index);
        setAccordionStates(
            accordionStates.filter(idx => idx !== indexOfIndex)
        );
    }

    const addServer = () => {
        serverForm.addServer();
        setAccordionStates([serverForm.forms.length]);
    }

    return (
        <FormFields alignItems={'center'}>
            <TransitionGroup style={{ width: '100%' }}>
                {
                    serverForm.forms.map((server, index) => {
                        return (
                            <Collapse unmountOnExit key={index}>
                                <Accordion disableGutters expanded={accordionStates.includes(index)} key={index}>
                                    <AccordionSummary
                                        sx={{
                                            background: theme.palette.action.hover,
                                        }}

                                        onClick={() => toggleAccordion(index)}
                                    >
                                        <Stack width={'100%'} direction={'row'} alignItems={'center'}
                                            justifyContent={'space-between'}>
                                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                                <Typography variant={'h6'}>Server {index + 1}</Typography>
                                                {
                                                    serverForm.doesServerHaveErrors(index) &&
                                                    <ErrorOutline color={'error'} fontSize={'small'}/>
                                                }
                                                <IconButton color={'primary'} size={'small'}>
                                                    {
                                                        accordionStates.includes(index) ?
                                                            <ExpandLessIcon /> : <ExpandMoreIcon />
                                                    }
                                                </IconButton>
                                            </Stack>

                                            <IconButton color={'error'} size={'small'} onClick={(event) => {
                                                event.stopPropagation();
                                                removeServer(index);
                                            }}>
                                                <Delete />
                                            </IconButton>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormFields>
                                            <FormGroupOutline showOverlay={disableForm}>
                                                <FormGroup>
                                                    <StyledFormFieldLabel>Name</StyledFormFieldLabel>
                                                    <FormTextField
                                                        helpText={'Lowercase letters, numbers, hyphens allowed up to 52 characters'}
                                                        formKey={ServerFormKeys.serverName}
                                                        fieldFn={(key) => serverForm.getField(key, index)}
                                                        disabled={disableForm}
                                                        onInputChange={(field, value) => {
                                                            serverForm.handleServerValueChange(field, value, index);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </FormGroupOutline>

                                            {/* Updated: Ensure that image name and value match */}
                                            <FormGroupOutline showOverlay={disableForm}>
                                                <FormGroup>
                                                    <StyledFormFieldLabel>Base Image</StyledFormFieldLabel>
                                                    <FormAutocompleteField
                                                        options={images.data.map(img => ({
                                                            label: img.name,
                                                            value: img.image
                                                        }))}
                                                        loading={images.pending}
                                                        placeholder={"Select an Image"}
                                                        formKey={ServerFormKeys.serverBaseImage}
                                                        disabled={disableForm}
                                                        fieldFn={(key) => serverForm.getField(key, index)}
                                                        onInputChange={(field, value) => {
                                                            serverForm.handleServerValueChange(field as keyof IServerForm, value, index);
                                                        }}
                                                        isOptionEqualToValue={(option, value) => option.value === value}
                                                        getOptionLabel={(option) => {
                                                            const selectedOption = images.data.find(img => img.image === option);
                                                            return selectedOption ? selectedOption.name : option.label || option;
                                                        }}
                                                    />
                                                </FormGroup>
                                            </FormGroupOutline>

                                            <ServerFormSettings
                                                disableForm={disableForm}
                                                serverForm={serverForm}
                                                serverIndex={index}/>

                                            <ServerNetworks
                                                disableForm={disableForm}
                                                server={server}
                                                serverForm={serverForm}
                                                serverIndex={index}
                                                localNetworkInterfaces={localNetworkInterfaces}
                                                remoteNetworkInterfaces={remoteNetworkInterfaces}/>

                                        </FormFields>
                                    </AccordionDetails>
                                </Accordion>
                            </Collapse>
                        )
                    })
                }

            </TransitionGroup>

            {
                serverForm.isEmpty() &&
                <EmptyListItem sx={{padding: theme.spacing(2)}}>

                    <EmptyListItemText>
                        {
                            specification.pending ? 'Loading...' : 'No Servers'
                        }
                    </EmptyListItemText>

                </EmptyListItem>
            }

            <Button disabled={disableForm} onClick={addServer} color={'secondary'} variant={'contained'}
                sx={{ width: '70%' }} endIcon={<Add />}>
                Add Server
            </Button>
        </FormFields>
    );
}

export default ServerForm;
