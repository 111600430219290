import {AppUiObjectNames, Users} from "../types/AppObjectNames";

export const
    // Main URLs
    URL_BASE: string = '/',
    URL_ERROR: string = '/error',
    URL_LOGIN: string = '/login',
    URL_LOGOUT: string = '/logout',
    URL_PRIVACY: string = '/privacy',

    // Teacher Routes
    URL_TEACHER_BASE: string = `/${Users.TEACHER.toLowerCase()}`,
    URL_TEACHER_HOME: string = `${URL_TEACHER_BASE}/home`,
    URL_TEACHER_SETTINGS: string = `${URL_TEACHER_BASE}/settings`,
    URL_TEACHER_UNIT: string = `${URL_TEACHER_BASE}/${AppUiObjectNames.UNIT.toLowerCase()}`,
    URL_TEACHER_SPECIFICATIONS_BASE: string = `${URL_TEACHER_BASE}/specifications`,
    URL_TEACHER_SPECIFICATION_EDIT: string = `${URL_TEACHER_SPECIFICATIONS_BASE}/edit`,
    URL_TEACHER_SERVERS: string = `${URL_TEACHER_BASE}/servers/manage`,
    URL_TEACHER_SERVERS_CREATE: string = `${URL_TEACHER_BASE}/servers/create`,

    // Student Routes
    URL_STUDENT_BASE: string = `/${Users.STUDENT.toLowerCase()}`,
    URL_STUDENT_JOIN: string = `/join`,
    URL_STUDENT_WORKOUT: string = `${URL_STUDENT_BASE}/${AppUiObjectNames.WORKOUT.toLowerCase()}`,

    // Admin Routes
    URL_ADMIN_BASE: string = `/${Users.ADMIN.toLowerCase()}`,
    URL_ADMIN_MANAGE_USERS: string = `${URL_ADMIN_BASE}/users`,
    URL_ADMIN_MANAGE_UNIT: string = `${URL_ADMIN_BASE}/manage/assignment`,
    URL_ADMIN_MANAGE_WORKOUT: string = `${URL_ADMIN_BASE}/manage/assignment/workout`,

    // Guacamole Routes
    URL_STUDENT_GUACAMOLE: string = `/${URL_STUDENT_WORKOUT}/:buildId/guacamole/:serverIdx`,
    URL_STUDENT_GUACAMOLE_TUNNEL: string = `${URL_STUDENT_WORKOUT}/{BUILD_ID}/guacamole/{SERVER_IDX}/session?token={TOKEN}`,

    // Docs Routes
    URL_DOCS_BASE: string = '/documents',
    URL_DOCS_INSTRUCTIONS: string = `/${URL_DOCS_BASE}/instructions/student/:buildId`,

    // API Routes
    URL_UNIT_API_BASE: string = "units/",
    URL_UNIT_API_ITEM: string = `${URL_UNIT_API_BASE}{ITEM_ID}/`,
    URL_UNIT_API_ITEM_STATE: string = `${URL_UNIT_API_ITEM}state/`,
    URL_UNIT_API_ITEM_ROSTER: string = `${URL_UNIT_API_ITEM}roster/`,
    URL_UNIT_API_ITEM_FULL: string = `${URL_UNIT_API_ITEM}full/`,
    URL_UNIT_API_ITEMS: string = `${URL_UNIT_API_BASE}{ITEM_ID}/workouts/`,
    URL_WORKOUT_API_BASE: string = `workouts/`,
    URL_WORKOUT_API_ITEM: string = `${URL_WORKOUT_API_BASE}{ITEM_ID}/`,
    URL_WORKOUT_API_ITEM_STATE: string = `${URL_WORKOUT_API_ITEM}state/`,
    URL_WORKOUT_API_ITEM_FULL: string = `${URL_WORKOUT_API_BASE}{ITEM_ID}/full/`,
    URL_WORKOUT_QUESTION_API_ITEM: string = `${URL_WORKOUT_API_ITEM}question/{Q_KEY}/`,
    URL_USER_API_BASE: string = "users/",
    URL_USER_API_UID: string = `${URL_USER_API_BASE}{ITEM_ID}/`,
    URL_USER_API_SETTINGS: string = `${URL_USER_API_UID}settings/`,
    URL_USER_API_COURSES: string = `${URL_USER_API_UID}courses/`,
    URL_COMPUTE_IMAGE_API_BASE: string = "compute/images/",
    URL_COMPUTE_IMAGE_API_ITEM: string = `${URL_COMPUTE_IMAGE_API_BASE}{ITEM_ID}/`,
    URL_COMPUTE_IMAGE_API_CREATE: string = `${URL_COMPUTE_IMAGE_API_BASE}create/`,
    URL_COMPUTE_IMAGE_API_PROJECT: string = `${URL_COMPUTE_IMAGE_API_BASE}project/`,
    URL_SPEC_API_BASE: string = "lab-specs/",
    URL_SPEC_STARTUP_SCRIPTS: string = `${URL_SPEC_API_BASE}startup-scripts/`,
    URL_SPEC_API_ITEM: string = `${URL_SPEC_API_BASE}{ITEM_ID}/`,
    URL_SPEC_API_UPLOAD: string = `${URL_SPEC_API_BASE}upload/`,
    URL_SPEC_API_EDIT: string = `${URL_SPEC_API_BASE}edit/`,
    URL_SPEC_API_EDIT_ITEM: string = `${URL_SPEC_API_EDIT}{ITEM_ID}/`,
    URL_DOCS_API_BASE: string = "docs/",
    URL_DOCS_API_ITEM: string = `${URL_DOCS_API_BASE}{ITEM_ID}/`,
    URL_DOCS_INSTRUCTIONS_API_BASE: string = `${URL_DOCS_API_BASE}instructions/`,
    URL_DOCS_INSTRUCTIONS_API_ITEM: string = `${URL_DOCS_API_BASE}instructions/{ITEM_ID}/`,
    URL_GUACAMOLE_API_BASE: string = "guacamole/",
    URL_GUACAMOLE_API_SESSION: string = `${URL_GUACAMOLE_API_BASE}{BUILD_ID}/servers/{SERVER_IDX}/`
;
