export class PubSub {
    static Topics = {
        CYBER_ARENA: "cyber-arena",
        AGENT_TELEMETRY: "agency-telemetry"
    };

    static Handlers = {
        BUDGET: "BUDGET",
        BUILD: "BUILD",
        MAINTENANCE: "MAINTENANCE",
        CONTROL: "CONTROL",
        ADMIN: "ADMIN",
        IOT: "IOT",
        AGENCY: "AGENCY"
    };

    static BuildActions = {
        WORKOUT: 0,
        UNIT: 1,
        FIXED_ARENA: 2,
        FIXED_ARENA_CLASS: 3,
        SERVER: 4,
        DISPLAY_PROXY: 5,
        FIREWALL_SERVER: 6,
        FIXED_ARENA_WORKSPACE_PROXY: 7,
        CYBER_ARENA_AGENT: 9,
        CYBER_ARENA_ATTACK: 10,
        CYBER_ARENA_WEAKNESS: 11
    };

    static Actions = {
        BUILD: 1,
        START: 2,
        DELETE: 3,
        STOP: 4,
        REBUILD: 5,
        SNAPSHOT: 6,
        RESTORE: 7,
        NUKE: 8,
        START_ESCAPE_ROOM_TIMER: 9,
        EXTEND_RUNTIME: 10,
        RESET_EXPIRATION: 11,
        IMAGE_CREATE: 12,
        IMAGE_SYNC: 13,
        IMAGE_SERVER_CREATE: 14,
        IMAGE_DELETE: 15,
        IMAGE_CHECK_IN: 16,
        IMAGE_CHECK_OUT: 17,
        DELETE_SNAPSHOT: 18,
        INSTANCE_DELETE: 19,
        IMAGE_CANCEL_CHANGES: 20
    };

    static CyberArenaObjects = {
        FIXED_ARENA: 1,
        FIXED_ARENA_CLASS: 2,
        FIXED_ARENA_WORKSPACE: 3,
        SERVER: 4,
        AGENT_MACHINE: 5,
        UNIT: 6,
        WORKOUT: 7
    };
}

