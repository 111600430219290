import React from 'react';
import { Outlet } from 'react-router-dom';
import {Grid, Typography, Link, Box, Container, styled} from "@mui/material";
import AgogeAppBar from './NavigationBar/AgogeAppBar';

interface LayoutProps {
    toggleTheme: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    isDarkMode: boolean;
    onSidebarToggle: () => void;
}

interface FooterProps {
    backgroundColor?: string;
}

const Copyright = () => {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}
            {' '}
            <Link color="inherit" href="https://www.bastazo.com/">
                Bastazo, Inc.
            </Link>{' '}
        </Typography>
    );
}

const Footer = ({ backgroundColor = 'background.paper' }: FooterProps) => {
    return (
        <Box
            id={"agoge-footer"}
            component="footer"
            marginTop={10}
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                bottom: 0,
                padding: 'fixed',
            }}
        >
            <Container maxWidth="sm">
                <Typography variant="body1" align="center">
                    Agoge
                </Typography>
                <Copyright />
            </Container>
        </Box>
    );
}

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const Layout: React.FC<LayoutProps> = ({ toggleTheme, isDarkMode, onSidebarToggle }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <AgogeAppBar
                onSidebarToggle={() => {}}
                toggleTheme={toggleTheme}
                isDarkMode={isDarkMode}
            />
            <Grid
                id={"content"}
                container
                justifyContent="center"
                alignItems="center"
            >
                <Outlet />
            </Grid>
            <Footer />
        </Box>
    );
};

export default Layout;
