import React, {useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QuizIcon from '@mui/icons-material/Quiz';

import {useAuth} from '../../../context/AuthContext';
import {Workout, WorkoutAssessment} from '../../../services/Workout/workout.model';
import {unitService} from '../../../services/Unit/unit.service';
import {workoutService} from '../../../services/Workout/workout.service';
import {URL_STUDENT_WORKOUT} from '../../../router/urls';
import {WorkoutStates} from '../../../types/AgogeStates';
import {PubSub} from '../../../types/PubSub';

import ConfirmationDialog from '../../Common/Confirmation';
import DataTable from '../../Common/DataTable';
import StatusBadge from '../../Common/Status/StatusBadge';
import {StartButton, StopButton} from '../../Buttons/ControlButtons';
import {useModal} from "mui-modal-provider";
import SimpleSnackbar from "../../Common/SnackBar/SnackBar";
import {LinkButton} from "../../Buttons/LinkButton";


interface UnitTableProps {
    buildId: string;
    workouts: Workout[];
    lms_available?: boolean;
    isExpired?: boolean;
}
interface StatusInfo {
    text: string;
    color: string;
}
interface Question {
    id: string;
    name: string | null;
    type: string;
    question: string;
    key: string | null;
    answer: string;
    script_assessment: boolean;
    complete: boolean;
}

const stateMapping: { [key in WorkoutStates]?: StatusInfo } = {
    [WorkoutStates.NOT_BUILT]: { text: "Not Built", color: '#b0b0b0' },
    [WorkoutStates.RUNNING]: { text: "Running", color: '#388e3c' },
    [WorkoutStates.STOPPING]: { text: "Stopping", color: '#ada500' },
    [WorkoutStates.READY]: { text: "Stopped", color: '#42a5f5' },
    [WorkoutStates.STARTING]: { text: "Starting", color: '#ada500' },
    [WorkoutStates.EXPIRED]: { text: "Expired", color: '#cccccc' },
    [WorkoutStates.BROKEN]: { text: "Broken", color: '#ff1744' },
    [WorkoutStates.DELETED]: { text: "Deleted", color: '#000000' },
};

const defaultState: StatusInfo = { text: "Working", color: '#b0a100' };

const isQuestionArray = (arr: any): arr is Question[] =>
    Array.isArray(arr) && arr.every(item => typeof item.question === 'string');

const UnitTable: React.FC<UnitTableProps> = (props) => {
    const {showModal} = useModal();
    const { user } = useAuth();
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentAssessment, setCurrentAssessment] = useState<WorkoutAssessment | null>(null);
    const questions: Question[] = currentAssessment && isQuestionArray(currentAssessment.questions)
        ? currentAssessment.questions
        : [];

    if (!user) return <Typography color="error">User not authenticated</Typography>;
    const handleBuildAll = async () => {
        setLoading(true);
        try {
            await unitService.post_action(String(props.buildId));
            showModal(SimpleSnackbar, {
                message: "All workouts build successfully!",
                severity: 'success',
                vertical: 'top',
            });
        } catch (error) {
            console.error('Error building all workouts:', error);
            showModal(SimpleSnackbar, {
                message: "Failed to build workouts!",
                severity: 'error',
                vertical: 'top',
            });
        } finally {
            setLoading(false);
        }
    };
    const handleOpenResponses = (assessment: any) => {
        try {
            const parsedAssessment = JSON.parse(assessment.assessment);
            setCurrentAssessment(parsedAssessment);
        } catch (error) {
            console.error('Error parsing assessment:', error);
        }
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleConfirmNuke = async () => {
        try {
            await workoutService.put_action(String(selectedRows[0]?.id || ''), String(PubSub.Actions.NUKE));
            showModal(SimpleSnackbar, {
                message: "Processing Nuke request ...",
                severity: 'success',
                vertical: 'top',
            });
        } catch (error) {
            console.error('Error performing nuke action:', error);
            showModal(SimpleSnackbar, {
                message: "Oops! Nuke request failed! Maybe we're out of stock?",
                severity: 'error',
                vertical: 'top',
            });
        }
    };

    const columns = [
        {
            field: 'state',
            headerName: 'State',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            renderCell: (params: any) => {
                const status = stateMapping[params.value as WorkoutStates] || defaultState;
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                        <StatusBadge color={status.color} label={status.text} />
                    </div>
                );
            }
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 120,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'student_email',
            headerName: 'Student Email',
            minWidth: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'workoutLink',
            headerName: '',
            minWidth: 175,
            align: 'center',
            renderCell: (params: any) => (
                <LinkButton
                    buildId={params.row.id}
                    target={URL_STUDENT_WORKOUT}
                    color={"primary"}
                    endIcon={<OpenInNewIcon/>}
                    label={"View Workout"}
                    width={"150px"}
                />
            )
        },
        {
            field: 'assessment',
            headerName: '',
            minWidth: 175,
            align:'center',
            renderCell: (params: any) => {
                if(params.row.assessment !== undefined) {
                    return(
                        <Button variant="contained" color="primary" onClick={() => handleOpenResponses(params.row)} endIcon={<QuizIcon />}>
                            Assessment
                        </Button>
                    );
                }
                return null;
            }
        }
    ];
    return (
        <Paper
            square={false}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                padding: "0 10px 10px 10px"
            }}
        >
            <Box sx={{ width: "100%" }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, m: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBuildAll}
                        disabled={loading || !props.lms_available}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Build All'}
                    </Button>
                    <StartButton
                        text="Start All"
                        use="assignment"
                        data={String(props.buildId)}
                        isDisabled={props.workouts.length === 0 || props.isExpired}
                    />
                    <StopButton
                        text="Stop All"
                        use="assignment"
                        data={String(props.buildId)}
                        isDisabled={props.workouts.length === 0 || props.isExpired}
                    />
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setOpenDialog(true)}
                        disabled={selectedRows.length === 0 || props.isExpired}
                    >
                        Nuke
                    </Button>
                </Box>
                <DataTable
                    data={props.workouts}
                    columns={columns}
                    showSearch={true}
                    checkboxSelection={true}
                    onCheckboxChange={setSelectedRows}
                    loading={loading}
                />
                <ConfirmationDialog
                    open={openDialog}
                    handleClose={() => setOpenDialog(false)}
                    handleConfirm={async () => {
                        await handleConfirmNuke();
                        setOpenDialog(false);
                    }}
                    expectedText={selectedRows[0]?.id}
                />
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 'auto',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            Assessment
                        </Typography>
                        {questions.length > 0 ? (
                            <List>
                                {questions.map((question, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            m: 1,
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            padding: '10px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {question.complete && (
                                            <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                                        )}
                                        <ListItemText primary={question.question} />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>
                                No Responses
                            </Typography>
                        )}
                        <Button onClick={handleCloseModal} sx={{ mt: 2 }}>Close</Button>
                    </Box>
                </Modal>
            </Box>
        </Paper>
    );
};

export default UnitTable;
