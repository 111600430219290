import React, { useState } from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from 'react-router-dom';

import {
    URL_LOGIN,
    URL_LOGOUT,
    URL_PRIVACY,
    URL_TEACHER_HOME,
    URL_TEACHER_SETTINGS,
    URL_TEACHER_UNIT,
    URL_TEACHER_SPECIFICATION_EDIT,
    URL_TEACHER_SERVERS,
    URL_TEACHER_SERVERS_CREATE,
    URL_STUDENT_JOIN,
    URL_STUDENT_WORKOUT,
    URL_ADMIN_MANAGE_USERS,
    URL_ADMIN_MANAGE_UNIT,
    URL_ADMIN_MANAGE_WORKOUT,
    URL_STUDENT_GUACAMOLE,
    URL_STUDENT_GUACAMOLE_TUNNEL,
    URL_TEACHER_SPECIFICATIONS_BASE,
    URL_DOCS_API_BASE,
    URL_DOCS_INSTRUCTIONS,
    URL_ADMIN_BASE
} from './urls';
import Login from '../components/Firebase/Login';
import Logout from "../components/Firebase/Logout";
import TeacherHome from '../components/Teacher/Home/Home';
import TeacherUnit from "../components/Teacher/Unit/TeacherUnit";
import ServerManager from "../components/Teacher/ServerManagement/ServerManager";
import ServerCreator from "../components/Teacher/ServerManagement/ServerCreator";
import TeacherSettings from "../components/Teacher/Settings/Settings";
import StudentWorkout from "../components/Student/Workout";
import GuacamoleSession from "../components/Guacamole/EmbeddedSession";
import SessionWaitDialog from "../components/Guacamole/SessionWaitDialog";
import { AuthProvider, useAuth } from "../context/AuthContext";
import Layout from "../components/Common/Layout";
import JoinWorkout from "../components/Student/JoinWorkout";
import ProjectOverviewTabs from "../components/Admin/ProjectOverviewTabs";
import Editor from "../components/Teacher/Specifications/NewEditor/Editor";
import FileViewer from "../components/Common/FileViewer/FileViewer";
import UserManager from "../components/Admin/UserManager"
import AppError from "../components/Common/Errors/AppError";
import CatalogTable from "../components/Teacher/Specifications/SpecificationTables/CatalogTable";
import CatalogEditTable from "../components/Teacher/Specifications/SpecificationTables/CatalogEditTable";

interface ProtectedRouteProps {
    element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const { currentUser } = useAuth();

    return currentUser ? element : <Navigate to={URL_LOGIN} />;
};

const DefaultHome: React.FC = () => {
    const { currentUser } = useAuth();

    return (
            currentUser
            ? <Navigate to={URL_TEACHER_HOME} replace={true}/>
            : <Navigate to={URL_LOGIN} replace={true}/>
        );
};


interface AppRouterProps {
    toggleTheme: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    isDarkMode: boolean;
}

const AppRouter: React.FC<AppRouterProps> = (
    { toggleTheme, isDarkMode }
) => {
    const [
        sidebarOpen,
        setSidebarOpen
    ] = useState(true);
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Layout toggleTheme={toggleTheme} isDarkMode={isDarkMode} onSidebarToggle={toggleSidebar} />,
            errorElement: <AppError />,
            children: [
                { index: true, element: <DefaultHome /> },
                { path: URL_TEACHER_HOME, element: <ProtectedRoute element={<TeacherHome />} /> },
                { path: URL_TEACHER_SETTINGS, element: <ProtectedRoute element={<TeacherSettings />} /> },
                { path: URL_TEACHER_SERVERS, element: <ProtectedRoute element={<ServerManager />} /> },
                { path: URL_TEACHER_SERVERS_CREATE, element: <ProtectedRoute element={<ServerCreator />} /> },
                { path: URL_TEACHER_SPECIFICATIONS_BASE, element: <ProtectedRoute element={<CatalogTable />} /> },
                { path: URL_TEACHER_SPECIFICATION_EDIT, element: <ProtectedRoute element={<CatalogEditTable />} /> },
                { path: `${URL_TEACHER_SPECIFICATION_EDIT}/:edit_id`, element: <ProtectedRoute element={<Editor />} /> },
                { path: `${URL_TEACHER_UNIT}/:build_id`, element: <ProtectedRoute element={<TeacherUnit />} /> },
                { path: URL_ADMIN_BASE, element: <ProtectedRoute element={<ProjectOverviewTabs />}/>},
                { path: URL_ADMIN_MANAGE_USERS, element: <ProtectedRoute element={<UserManager />} />},

                // Public Endpoints
                { path: `${URL_STUDENT_WORKOUT}/:build_id`, element: <StudentWorkout /> },
                { path: URL_DOCS_INSTRUCTIONS, element: <FileViewer title={"Student Instructions"} fileType={"markdown"}/> },
            ],
        },
        { path: "error", element: <AppError /> },
        { path: URL_LOGIN, element: <Login /> },
        { path: URL_LOGOUT, element: <Logout /> },
        { path: URL_STUDENT_GUACAMOLE, element: <SessionWaitDialog /> },
        { path: URL_STUDENT_JOIN, element: <JoinWorkout /> },
    ]);

    return (
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    );
};

export default AppRouter;
