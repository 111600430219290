import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Box, Skeleton } from '@mui/material';
import InfoCard from "../../Common/InfoCard";
import TimerBox from "../../Common/Timer";
import { useAuth } from "../../../context/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import { UnitFull, UnitRoster } from "../../../services/Unit/unit.model";
import { unitService } from "../../../services/Unit/unit.service";
import { Workout } from "../../../services/Workout/workout.model";
import UnitTable from "./UnitTable";
import HttpError from "../../Common/Errors/HttpError";
import { URL_ERROR } from "../../../router/urls";
import { WorkoutStates } from "../../../types/AgogeStates";

const TeacherUnit: React.FC = () => {
    const { currentUser } = useAuth();
    const { build_id } = useParams<{ build_id: string }>();
    const [unitFull, setUnitFull] = useState<UnitFull | null>(null);
    const [expiresTimestamp, setExpiresTimestamp] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isExpired, setIsExpired] = useState(false);
    const [workoutList, setWorkoutList] = useState<Workout[]>([]);
    const previousWorkoutListLengthRef = useRef(0);
    const [rosterSize, setRosterSize] = useState<UnitRoster | undefined>();
    const navigate = useNavigate();

    const handleError = useCallback(
        (error: HttpError) => {
            if ([404].includes(error.status)) {
                navigate(URL_ERROR, {
                    state: {
                        status: error.status,
                        message: error.message || "An error occurred",
                    },
                });
            } else {
                navigate(URL_ERROR, {
                    state: {
                        status: 500,
                        message: "Something went wrong!",
                    },
                });
            }
        },
        [navigate]
    );

    const fetchData = useCallback(async (isInitialLoad = false) => {
        if (isInitialLoad) setIsLoading(true);

        const checkExpiration = (fetchedUnitFull: UnitFull) => {
            const expiry = fetchedUnitFull?.workspace_settings?.expires;
            setExpiresTimestamp(Number(expiry) || 0);
            const state = fetchedUnitFull?.state;

            if (expiry) {
                const currentDate = new Date();
                const expiryDate = new Date(Number(expiry) * 1000);
                setIsExpired(expiryDate < currentDate);
            } else {
                setIsExpired([WorkoutStates.EXPIRED, WorkoutStates.DELETED].includes(Number(state)));
            }
        };

        try {
            const fetchedUnitFull = await unitService.get_full(String(build_id));
            setUnitFull(fetchedUnitFull);
            if (fetchedUnitFull) {
                checkExpiration(fetchedUnitFull);
                setWorkoutList(fetchedUnitFull.workouts || []);
                setRosterSize({ id: String(fetchedUnitFull.id), roster: fetchedUnitFull.roster });
                previousWorkoutListLengthRef.current = fetchedUnitFull.workouts?.length || 0;
            }
        } catch (error) {
            handleError(error as HttpError);
            setUnitFull(null);
        } finally {
            if (isInitialLoad) setIsLoading(false);
        }
    }, [build_id, handleError]);

    useEffect(() => {
        if (currentUser) {
            fetchData(true);  // Initial load with loading state
        } else {
            setIsLoading(false);
        }
    }, [fetchData, currentUser]);

    // Initial data fetch effect for workout data
    useEffect(() => {
        if (currentUser && !isExpired) {
            const intervalId = setInterval(() => fetchData(), 3500);
            return () => clearInterval(intervalId);
        }
    }, [fetchData, currentUser, isExpired]);
    const memoizedRosterSize = useMemo(() => rosterSize?.roster, [rosterSize]);
    return (
        <Box
            id="homePage"
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-start',
                mt: 10,
                pl: 2,
                pr: 2
            }}
        >
            <Box
                sx={{
                    width: '25%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mr: 4
                }}
            >
                {isLoading || !unitFull ? (
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={150}
                        animation="wave"
                        sx={{ bgcolor: 'rgba(0, 0, 0, 0.1)' }}
                    />
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <TimerBox
                            type="unit"
                            build_id={String(build_id)}
                            unitData={unitFull!}
                        />
                    </Box>
                )}
            </Box>
            <Box sx={{ width: '75%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {isLoading || !unitFull ? (
                    <>
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={200}
                            animation="wave"
                            sx={{ bgcolor: 'rgba(0, 0, 0, 0.1)' }}
                        />
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={500}
                            animation="wave"
                            sx={{ mt: 2, bgcolor: 'rgba(0, 0, 0, 0.1)' }}
                        />
                    </>
                ) : (
                    <>
                        <InfoCard type='unit' data={{ ...unitFull, roster: memoizedRosterSize }} />
                        <Box sx={{ mt: 2, width: '100%' }}>
                            <UnitTable
                                buildId={String(build_id)}
                                workouts={workoutList}
                                lms_available={unitFull?.lms_integration !== null}
                                isExpired={isExpired}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default TeacherUnit;
