import React from "react";
import {useAuth} from "../../../context/AuthContext";
import {Backdrop, Box, Skeleton} from "@mui/material";
import ImageListTable from "./ImageListTable";

const ServerManager: React.FC = () => {
    const {currentUser } = useAuth();

    return (
        <>
            <Box
                width={"80%"}
                height={"auto"}
                sx={{marginTop: "100px"}}
            >
                { currentUser ? (
                    <ImageListTable />
                ) : (
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                        open={true}
                    >
                        <Skeleton variant="rectangular" width="100%" height={50} />
                    </Backdrop>
                )}
            </Box>
        </>
    );
};

export default ServerManager;