import {Button} from "@mui/material";
import React from "react";

interface ButtonProps {
    variant?: "contained" | "outlined";
    buildId: string;
    target: string;
    color?: "primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
    endIcon?: React.ReactNode;
    startIcon?: React.ReactNode;
    label?: string;
    width?: string;
}

export const LinkButton: React.FC<ButtonProps> = (props) => {
    const url = `${props.target}/${props.buildId}`;
    const btnWidth = props.width ? props.width : '120px';

    return (
        <Button
            variant={props.variant ? props.variant : "contained"}
            color={props.color ? props.color : 'primary'}
            href={url}
            style={{ width: btnWidth }}
            target={"_blank"}
            endIcon={props?.endIcon}
            startIcon={props?.startIcon}
            aria-label={`Link to ${url}`}
        >
            {props.label ? props.label : props.buildId}
        </Button>
    );
};
