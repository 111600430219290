import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    ReactNode
} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import FirebaseApp from '../components/Firebase/firebase';
import { SafeAgogeUser } from '../services/User/user.model';

FirebaseApp();

interface AuthProviderProps {
    children: ReactNode;
}

interface AuthContextType {
    currentUser: firebase.User | null;
    user: SafeAgogeUser | null;
    setUser: (user: SafeAgogeUser) => void;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
    const [user, setUser] = useState<SafeAgogeUser | null>(() => {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = firebase.auth().onIdTokenChanged(async (currentUser) => {
            setCurrentUser(currentUser);
            setLoading(false);

            if (user) {
                const storedUser = localStorage.getItem('user');
                if (storedUser) {
                    setUser(JSON.parse(storedUser));
                }
                sessionStorage.setItem('authUser', JSON.stringify(user));
            } else {
                setUser(null);
                sessionStorage.removeItem('authUser');
            }
        })
        // return firebase.auth().onAuthStateChanged((user) => {
        //     setCurrentUser(user);
        //     setLoading(false);
        //
        //     // Check if user is authenticated and load custom user data from localStorage
        //     if (user) {
        //         const storedUser = localStorage.getItem('user');
        //         if (storedUser) {
        //             setUser(JSON.parse(storedUser));
        //         }
        //         sessionStorage.setItem('authUser', JSON.stringify(user));
        //     } else {
        //         setUser(null);
        //         sessionStorage.removeItem('authUser');
        //     }
        // });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const storedAuthUser = sessionStorage.getItem('authUser');
        if (storedAuthUser) {
            const parsedUser = JSON.parse(storedAuthUser);
            setCurrentUser(parsedUser);
        }
    }, []);

    const setUserAndStore = (user: SafeAgogeUser) => {
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
    };

    const logout = async () => {
        await firebase.auth().signOut();
        setCurrentUser(null);
        setUser(null);
        localStorage.removeItem('user');
        sessionStorage.removeItem('authUser');
    };

    return (
        <AuthContext.Provider value={{ currentUser, user, setUser: setUserAndStore, logout }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
