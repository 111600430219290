import React, {useEffect, useState} from 'react';
import { getFirebaseAuthUi, configureFirebaseLoginWidget } from './firebaseAuth';
import { Box, CircularProgress, Typography } from '@mui/material';
import {AgogeLogo} from "../Common/Logo";
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from "react-router-dom";

const FirebaseAuth: React.FC = () => {
    const { setUser } = useAuth();
    const [redirect, setRedirect] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const ui = getFirebaseAuthUi();
        const uiConfig = configureFirebaseLoginWidget(setUser, setRedirect);
        const element = document.querySelector('#firebaseui-auth-container');

        if (element) {
            ui.start('#firebaseui-auth-container', uiConfig);
        } else {
            console.error("FirebaseUI container element not found.");
        }

        return () => {
            ui.reset();
        };
    }, [setUser]);

    useEffect(() => {
        if (redirect) {
            navigate(redirect);
        }
    }, [redirect, navigate]);

    return (
        <Box id="main" sx={{
            backgroundColor: '#f5f5f5',
            borderRadius: 4,
            p: 2,
            boxShadow: 3,
            maxWidth: 500,
        }}>
            <AgogeLogo contained={false}/>
            <Box id="login_container" sx={{ textAlign: 'center', maxWidth: 500, mx: 'auto' }}>
                <Box id="firebaseui-auth-container"></Box>
                <Box id="loader" sx={{ display: 'none', textAlign: 'center' }}>
                    <CircularProgress />
                </Box>
                <Typography variant="body1" color="error" id="error-msg"></Typography>
            </Box>
        </Box>
    );
};

export default FirebaseAuth;
