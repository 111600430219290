import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
    useTheme
} from '@mui/material';

interface DialogProps {
    title: string;
    open: boolean;
    onClose: () => void;
    textContent: string;
}

const ValidationDialog: React.FC<DialogProps> = (
    {
        title,
        open,
        onClose,
        textContent,
    }
) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    return (
        <Dialog open={open} onClose={onClose} disableEnforceFocus>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography
                    sx={{
                        color: isDarkMode ? "red" : "darkred",
                    }}
                >
                    {textContent}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ValidationDialog;
