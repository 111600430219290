const tagColors: { [key: string]: { backgroundColor: string, color: string } } = {
    'Ethics': { backgroundColor: '#b2d4ff', color: 'black' },
    'Establishing Trust': { backgroundColor: '#b3f5ff', color: 'black' },
    'Ubiquitous Computing': { backgroundColor: '#abf6d1', color: 'black' },
    'Data Security': { backgroundColor: '#febead', color: 'black' },
    'System Security': { backgroundColor: '#bfb5f2', color: 'black' },
    'Adversarial Thinking': { backgroundColor: '#b3bac5', color: 'black' },
    'Risk': { backgroundColor: '#fef0b3', color: 'black' },
    'Implications': { backgroundColor: '#00bf78', color: 'black' },
    'Courses': { backgroundColor: '#6e2639', color: 'white' },
    'Evaluation': { backgroundColor: '#01412c', color: '#ffffff' },
    'Exercise': { backgroundColor: '#463a39', color: '#ffffff' },
    'Practice Evaluation': { backgroundColor: '#f3bf0d', color: 'black' },
    'Prerequisites': { backgroundColor: '#000099', color: 'white' }
};

export default tagColors;