// Service
import {URL_COMPUTE_IMAGE_API_PROJECT} from "../../router/urls";
import { apiService } from "../api-request.service";
import { ImageSummaryLists } from "./image.model";

const list = async(): Promise<ImageSummaryLists> => {
    return await apiService.get<ImageSummaryLists>(URL_COMPUTE_IMAGE_API_PROJECT, null, true);
}

export const serverService = {
    list,
}
